<template>
  <div id="buyStepComBox" class="buy-step-com" v-loading="loading">
    <div id="topBoxH" class="topBox y-start" :style="{ width: `${topBoxWidth}px` }">
      <div class="top-btns x-c-end">
        <el-button size="mini" v-if="[1, 2, 3].includes(step)" @click="updateStep()">上一步</el-button>
        <el-button size="mini" v-if="[1, 2].includes(step)" @click="updateStep(true)">下一步</el-button>
        <el-button size="mini" v-if="step === 4" @click="updateStep(true)">完成</el-button>
      </div>

      <cardTitleCom cardTitle="当前进度" style="width: 100%; margin-bottom: 0px">
        <template slot="cardContent">
          <div class="padd10">
            <el-steps :active="step" size="mini" align-center class="step">
              <el-step v-for="title in [
                  '选择产品规格',
                  '确认订单',
                  '去支付',
                  '支付成功',
                ]" :key="title" :title="title"></el-step>
            </el-steps>
          </div>
        </template>
      </cardTitleCom>
    </div>

    <div class="bottomBox" :style="{ height: `${tableHeight}px` }">
      <cardTitleCom cardTitle="产品规格" v-show="step === 1" style="margin-bottom: 0">
        <template slot="cardContent">
          <div style="margin: 10px">
            <div class="x-w setMealBox marL30" style="width: 100%">
              <div class="x-bc setMeal cursorP" v-for="(item, index) in product" :key="index"
                :class="[index === active ? 'setMealBox-on' : '']" @click="updateForm('active', index)">
                <div>
                  <b>{{ item.productName }}</b>
                </div>
              </div>
            </div>
            <myForm label-position="left-label" v-if="showForm" v-model="productForm" ref="myForm"
              :options="productFormOption" label-width="120px" />
          </div>
        </template>
      </cardTitleCom>

      <div v-show="step === 2" v-if="orderDetail">
        <cardTitleCom cardTitle="产品规格">
          <template slot="cardContent">
            <el-descriptions :column="1" labelClassName="my-descriptions-label">
              <el-descriptions-item label="服务名称">
                {{
                  `${product[active].parentProductName}（${productData[active].productName}）`
                }}
              </el-descriptions-item>
              <el-descriptions-item label="规格">
                <span v-if="productForm.userNumber > 0">{{ productForm.userNumber }}个员工账户</span>
                <span v-if="productForm.shopNumber > 0">
                  <span v-if="productForm.userNumber > 0">+</span>{{ productForm.shopNumber }}个门店数</span>
                <span v-if="productForm.possNumber > 0">
                  <span v-if="
                      productForm.userNumber > 0 || productForm.shopNumber > 0
                    ">+</span>{{ productForm.possNumber }}个站点数</span>
              </el-descriptions-item>
              <el-descriptions-item label="有效期">{{
                orderDetail.unit
              }}</el-descriptions-item>
              <el-descriptions-item label="原价">{{ Number(orderDetail.oldOrderMoney).toFixed(2) }}元
              </el-descriptions-item>
              <el-descriptions-item label="优惠券">无可用优惠券</el-descriptions-item>
              <el-descriptions-item label="合计">{{ Number(orderDetail.orderMoney).toFixed(2) }}元
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="产品规格">
          <template slot="cardContent">
            <div class="padd10">
              <el-checkbox v-model="checked"></el-checkbox>
              <span style="font-size: 12px; margin-left: 5px; line-height: 23px">
                我已阅读并同意
                <el-link type="primary" style="font-size: 12px">《合同条款》</el-link>
              </span>
              <div style="height: 80px"></div>
            </div>
          </template>
        </cardTitleCom>
      </div>

      <cardTitleCom cardTitle="支付方式" v-show="step === 3" v-if="orderDetail">
        <template slot="cardContent">
          <div class="pay-code">
            <div style="line-height: 30px; padding: 15px 0; font-size: 16px">
              应付金额：
              <span style="font-size: 30px; color: #ff5b28">{{ orderDetail.orderMoney }}元</span>
            </div>
            <img :src="QRImgUrl" class="QRImgUrl" style="width: 180px; height: 180px" />
            <div style="
                color: #666666;
                font-size: 14px;
                padding: 15px 0;
                display: flex;
                algin-item: center;
                justify-content: center;
              ">
              <el-image :src="require('@/assets/images/weixinpay.png')" class="icon" />
              <div>微信支付</div>
              <el-image :src="require('@/assets/images/zhifubaopay.png')" class="icon" />
              <div>支付宝支付</div>
              <el-image :src="require('@/assets/images/yunshanpay.png')" class="icon" />
              <div>云闪付</div>
            </div>
            <div style="color: #666666; font-size: 14px; padding: 15px 0">
              温馨提示：产品一经激活启用，非产品原因不允许退货退款
            </div>
          </div>
        </template>
      </cardTitleCom>

      <cardTitleCom cardTitle="购买状态" v-if="step === 4">
        <div class="pay-code" slot="cardContent">
          <i class="el-icon-circle-check" style="color: #28ac3f; font-size: 48px; margin: 15px"></i>
          购买成功
          <div>
            <el-link type="primary" @click="goBack">返回</el-link>
          </div>
        </div>
      </cardTitleCom>
    </div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import myForm from "@/components/my-form";
import { deepCopy } from "@/utils";
import { buyOrderData, getProductList } from "@/api/tenant/base/info";
import { orderPay, checkOrderPayState } from "@/api/creation/index.js";
import { payConfigList } from "@/api/tenant/product/order";
import { mapMutations } from "vuex"; //选择短信产品弹窗
export default {
  name: "buyStep",
  components: { swiper, swiperSlide, cardTitleCom, myForm },
  props: ["inProductId"],
  data() {
    return {
      getMinPay: (data) => {
        if (!this.showForm || !this.yearsData) {
          return { pay: "", text: "" };
        }
        let yearsData = data.payModelItems.map((x) => {
          const item = this.yearsData.find(
            (y) => y.dictValue === x.payModelType
          );
          let payModelPrice = 0;
          let [{ shopsPrice = 0 } = {}] = deepCopy(
            x.saasProductShopsPrices
          ).sort((a, b) => b.shopsPrice - a.shopsPrice); // 门店最低单价
          let [{ usersPrice = 0 } = {}] = deepCopy(
            x.saasProductUsersPrices
          ).sort((a, b) => b.usersPrice - a.usersPrice); // 门店最低单价

          // shopsPrice = (x.initializeShops * x.initializeShopsPrice) + shopsPrice
          // usersPrice = (x.initializeUsers * x.initializeUsersPrice) + usersPrice

          payModelPrice = shopsPrice + usersPrice; //+ data.productPrice
          // 获取最小价格
          return { payModelPrice, label: item?.dictLabel || "" };
        });

        // 取折扣后金额
        const priceArr = yearsData.sort(
          (a, b) => a.payModelPrice - b.payModelPrice
        );
        // 取一个最低价显示
        return {
          pay: priceArr[0]?.payModelPrice || 0.0,
          text: priceArr[0]?.label || "年",
        };
      },
      active: 0, //
      dateActive: 0, // 时长
      showForm: false,
      QRImgUrl: "",
      checked: false,
      loading: false,
      step: 1,
      payImg: "",
      productFormOption: [],
      productForm: {},
      dictRes: [],
      productData: [],
      timeing: 0, // 监控之间
      timer: null, // 支付状态计时器
      yearsData: [],
      createdData: null,
      type: undefined, // 1: 续费  2：升级
      tenantId: undefined, // 操作的商户ID
      product: [],
      shopsTableSelect: [], // 选择续费的门店
      usersTableSelect: [], // 选择续费的用户
      topBtnBoxWidth: 0,
      vivwH: 0,
      topBoxH: 0,
      payList: [],

      isOnePage: true,

      differences: "", // 升级折扣价object
    };
  },
  computed: {
    tableHeight() {
      return this.vivwH;
    },
    topBoxWidth() {
      this.$nextTick(() => {
        this.topBtnBoxWidth =
          document.getElementById("buyStepComBox").clientWidth;
      });
      return this.topBtnBoxWidth - 20;
    },
    // 计算用户订详情
    orderDetail() {
      const oneKey = "duration";
      const twoKey = "userNumber";
      const threeKey = "shopNumber";
      const possKey = "possNumber";

      const oneData = this.yearsData;
      const val = this.productForm;

      const {
        label,
        productPrice,
        productOldPrice,
        productId: pdID,
        payModelId: pmID,
        initializeUsers,
        initializeShops,
        initializePoss,
        saasProductUsersPrices,
        saasProductShopsPrices,
        saasProductPossPrices,
        isManageShops,
        isManageUsers,
        isManagePoss,
      } = oneData?.find((x) => x.value === val[oneKey]) || {};
      this.productForm.money = "0" + "元";
      if (!this.productForm[oneKey] || !pdID) {
        return null;
      }
      let money = 0; // 订单价格
      let oldMoney = 0; // 订单原价 前端用
      let unit = ""; // 单位 前端用
      let payModelId = null; // 包年付费方式ID
      let productId = null; // 购买产品ID
      let shopsPriceId = null; // 门店数价格ID
      let usersPriceId = null; // 用户数价格ID
      let possPriceId = null; // 站点数价格ID

      let usersOldMoney = 0; // 用户原价  前端用
      let usersMoney = 0; // 用户支付价格  前端用
      let showUsersPrice = 0; // 用户展示的单价

      let shopsOldMoney = 0; // 门店原价  前端用
      let shopsMoney = 0; // 门店支付价格  前端用
      let showShopPrice = 0; // 门店展示的单价

      let possOldMoney = 0; // 站点原价  前端用
      let possMoney = 0; // 站点支付价格  前端用
      let showPossPrice = 0; // 站点展示的单价

      // 购买时常价格
      if (label && pdID) {
        unit = label;
        productId = pdID;
        payModelId = pmID;
      } else {
        return null;
      }

      // 购买人数价格
      if (isManageUsers) {
        let twoData = saasProductUsersPrices?.sort?.(
          (a, b) => b.users - a.users
        );

        const {
          usersPrice = 0,
          usersOldPrice = 0,
          usersPriceId: upId,
        } = twoData?.find((x) => val[twoKey] >= x.users) || {};

        /*
         * this.productForm[twoKey] 当前选中的用户数
         * usersOldPrice   用户单价
         * */
        usersOldMoney = usersOldPrice * this.productForm[twoKey]; // 用户原价  前端用
        usersMoney = usersPrice * this.productForm[twoKey]; // 用户支付价格  前端用
        showUsersPrice = usersPrice; // 用来展示的用户单价
        money = money + usersMoney; // + (initializeUsersPrice * initializeUsers)

        console.log(
          usersMoney,
          usersOldMoney,
          usersPrice,
          saasProductShopsPrices,
          twoData,
          twoData
        );
        oldMoney = oldMoney + usersOldMoney;
        usersPriceId = upId;
      }

      // 购买门店价格
      if (isManageShops) {
        let threeData = saasProductShopsPrices?.sort?.(
          (a, b) => b.shops - a.shops
        );
        const {
          shopsPrice = 0,
          shopsOldPrice = 0,
          shopsPriceId: spId,
        } = threeData?.find((x) => val[threeKey] >= x.shops) || {};
        /*
         * this.productForm[twoKey] 当前选中的用户数
         * usersOldPrice   用户单价
         * */
        //shopsOldMoney = (initializeShops * initializeShopsPrice) + (shopsOldPrice * (this.productForm[threeKey] - initializeShops)) // 用户原价  前端用
        shopsOldMoney = shopsOldPrice * this.productForm[threeKey];
        // shopsMoney = (initializeShops * initializeShopsPrice) + (shopsPrice * (this.productForm[threeKey] - initializeShops)) // 用户支付价格  前端用
        shopsMoney = shopsPrice * this.productForm[threeKey];

        showShopPrice = shopsPrice; // 用于显示提示

        money = money + shopsMoney; //+ Number((initializeShopsPrice * initializeShops).toFixed(2))

        oldMoney = oldMoney + shopsOldMoney;
        shopsPriceId = spId;
      }

      // 购买站点价格
      if (isManagePoss) {
        let threeData = saasProductPossPrices?.sort?.(
          (a, b) => b.poss - a.poss
        );
        const {
          possPrice = 0,
          possOldPrice = 0,
          possPriceId: ppId,
        } = threeData?.find((x) => val[possKey] >= x.poss) || {};
        possOldMoney = possOldPrice * this.productForm[possKey];
        possMoney = possPrice * this.productForm[possKey];
        showPossPrice = possPrice; // 用于显示提示
        money = money + possMoney;
        oldMoney = oldMoney + possOldMoney;
        possPriceId = ppId;
      }
      // 去下浮点

      money =
        +this.$route.query.type === 2
          ? this.differences.totalDifferencesOrderMoney || 0
          : money;

      money = money % 1 === 0 ? money : Number(money.toFixed(2));
      oldMoney = oldMoney % 1 === 0 ? oldMoney : Number(oldMoney.toFixed(2));
      // money = money + Number(productPrice) // 按数量：续费时不在计算产品价格

      this.productForm.money = money + "元";

      const obj = {
        ...val,
        orderMoney: money,
        oldOrderMoney: oldMoney,
        unit,
        payModelId,
        productId,
        shopsPriceId,
        usersPriceId,
        possPriceId,
        shopsQty: val[threeKey],
        usersQty: val[twoKey],
        possQty: val[possKey],
        usersOldMoney,
        usersMoney,
        shopsOldMoney,
        shopsMoney,
        possOldMoney,
        possMoney,
        initializeUsers,
        initializeShops,
        initializePoss,
        showUsersPrice,
        showShopPrice,
        showPossPrice,
        linkManTel: String(val.linkManTel),
      };

      this.QRImgUrl = "";

      console.log(obj);
      return obj;
    },
  },
  created() {
    this.tenantId = this.$route.query.tenantId;
    this.getPayList();
  },
  async mounted() {
    try {
      const { data } = await this.getDicts("product_years_package_mode");
      this.dictRes = data;
      let res = await getProductList({
        productId: this.$route.query.productId,
      });
      this.product = res.data;
      this.productData = res.data;
      await this.updateForm();
      this.$nextTick(() => {
        this.topBtnBoxWidth =
          document.getElementById("buyStepComBox").clientWidth;
        this.vivwH = document.getElementById("buyStepComBox").clientHeight;
        this.topBoxH = document.getElementById("topBoxH").clientHeight;
        window.addEventListener("resize", this.handleResize);
        this.$refs.myForm.$refs.shopsTableRef?.[0].toggleAllSelection();
        this.$refs.myForm.$refs.usersTableRef?.[0].toggleAllSelection();
      });
      this.loading = false;
    } catch (error) { }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    "productForm.duration": {
      handler(newDuration) {
        if (this.showForm && newDuration && !this.isOnePage) {
          this.updateForm(
            "duration",
            this.productData[this.active].payModelItems.findIndex(
              (x) => x.payModelType === newDuration
            )
          );
        }
      },
    },
    usersTableSelect(nVal) {
      if (nVal.length >= 1) {
        this.productForm.userNumber = nVal.length;
      }
    },
    shopsTableSelect(nVal) {
      if (nVal.length >= 1) {
        this.productForm.shopNumber = nVal.length;
      }
    },
    possTableSelect(nVal) {
      if (nVal.length >= 1) {
        this.productForm.possNumber = nVal.length;
      }
    },
  },
  methods: {
    ...mapMutations("tagsView", ["DEL_VISITED_VIEW"]),

    // 返回上一页
    goBack() {
      this.$router.go(-1);
      const find = this.$store.getters.visitedViews.find(
        (item) => item.path === this.$route.path
      );
      this.DEL_VISITED_VIEW(find);
    },

    handleResize() {
      this.topBtnBoxWidth =
        document.getElementById("buyStepComBox").clientWidth;
      this.vivwH = document.getElementById("buyStepComBox").clientHeight;
      this.topBoxH = document.getElementById("topBoxH").clientHeight;
    },

    // 获取支付
    getPayList() {
      payConfigList().then((res) => {
        this.payList = res.rows;
      });
    },

    async updateForm(key, value) {
      if (key) {
        this[key] = value;
      }
      const isDuration = key === "duration";
      try {
        this.showForm = false;
        const {
          payModelItems,
          maxUsers,
          maxShops,
          maxPoss,
          productPrice,
          productOldPrice,
        } = this.productData[this.active];

        if (!isDuration) {
          this.yearsData = payModelItems.map((x) => {
            const item = this.dictRes.find(
              (y) => y.dictValue === x.payModelType
            );
            return {
              ...x,
              value: x.payModelType,
              label: item.dictLabel,
              productPrice,
              productOldPrice,
            };
          });
        }
        const {
          initializeShops,
          initializeUsers,
          initializePoss,
          initializeShopsPrice,
          initializeUsersPrice,
          initializePossPrice,
          saasProductUsersPrices,
          saasProductShopsPrices,
          saasProductPossPrices,
          isManageShops,
          isManageUsers,
          isManagePoss,
          value: durationValue,
        } = this.yearsData[isDuration ? value : 0];

        console.log(this.orderDetail);
        const userShops = [
          saasProductUsersPrices?.[0]?.usersPrice && isManageUsers
            ? {
              model: "userNumber",
              type: "input-number",
              label: "在线用户数",
              append: {
                type: "xyt-users",
                show: true,
                isInitial: false, //初始数据样式
                getPrice: () => ({
                  unit: this.orderDetail.unit,
                  showUsersPrice: this.orderDetail.showUsersPrice,
                  orderMoney: this.orderDetail.usersMoney,
                  oldOrderMoney:
                    this.orderDetail.usersOldMoney >
                      this.orderDetail.usersMoney
                      ? this.orderDetail.usersOldMoney
                      : "",
                  discounted:
                    this.differences !== ""
                      ? this.differences.differencesUserOrderMoney || 0
                      : undefined,
                }),
              },
              data: saasProductUsersPrices,
              min: 1,
              max: maxUsers,
              initializeUsers: initializeUsers,
              initializeUsersPrice: initializeUsersPrice,
              // disabled: isManageUsers !== 1,
              precision: 0,
              rules: [
                {
                  required: true,
                  message: "请输入在线用户数",
                  trigger: ["blur", "change"],
                },
              ],
            }
            : null,
          saasProductShopsPrices?.[0]?.shopsPrice && isManageShops
            ? {
              model: "shopNumber",
              type: "input-number",
              label: "门店个数",
              data: saasProductShopsPrices,
              min: 1,
              max: maxShops,
              initializeShops: initializeShops,
              initializeShopsPrice: initializeShopsPrice,
              // disabled: isManageShops !== 1,
              precision: 0,
              append: {
                type: "xyt-users",
                show: true,
                isInitial: false, //初始数据样式
                getPrice: () => ({
                  showShopPrice: this.orderDetail.showShopPrice,
                  unit: this.orderDetail.unit,
                  orderMoney: this.orderDetail.shopsMoney,
                  oldOrderMoney:
                    this.orderDetail.shopsOldMoney >
                      this.orderDetail.shopsMoney
                      ? this.orderDetail.shopsOldMoney
                      : "",
                  discounted:
                    this.differences !== ""
                      ? this.differences.differencesShopOrderMoney || 0
                      : undefined,
                }),
              },
            }
            : null,
          saasProductPossPrices?.[0]?.possPrice && isManagePoss
            ? {
              model: "possNumber",
              type: "input-number",
              label: "站点个数",
              data: saasProductPossPrices,
              min: 1,
              max: maxPoss,
              initializePoss: initializePoss,
              initializePossPrice: initializePossPrice,
              precision: 0,
              append: {
                type: "xyt-users",
                show: true,
                isInitial: false, //初始数据样式
                getPrice: () => ({
                  showPossPrice: this.orderDetail.showPossPrice,
                  unit: this.orderDetail.unit,
                  orderMoney: this.orderDetail.possMoney,
                  oldOrderMoney:
                    this.orderDetail.possOldMoney > this.orderDetail.possMoney
                      ? this.orderDetail.possOldMoney
                      : "",
                  discounted:
                    this.differences !== ""
                      ? this.differences.differencesPossOrderMoney || 0
                      : undefined,
                }),
              },
            }
            : null,
        ];

        let duration = [
          {
            model: "duration",
            type: "radio-button",
            label: "购买时长",
            rules: [
              {
                required: true,
                message: "请选择购买时长",
                trigger: ["blur", "change"],
              },
            ],
            optionData: this.yearsData,
          },
        ];
        this.productFormOption = [
          ...duration,
          ...userShops.filter((x) => x),
          {
            model: "money",
            type: "text-append",
            label: "合计",
            readonly: true,
          },
          {
            type: "divider",
          },
          {
            label: "支付方式",
            seniorSearch: true,
            type: "local",
            model: "payModel",
            filter: "payModeId",
            addNum: 2,
            width: "220px",
            option: {
              data: this.payList,
              dataKey: "rows",
              label: "payModeName",
              value: "payModeId",
              filterable: true,
            },
            rules: [
              {
                required: true,
                message: "请选择支付方式",
                trigger: ["blur", "change"],
              },
            ],
          },
          {
            prop: "credentialUrl",
            model: "credentialUrl",
            type: "upload-one",
            label: "支付凭证",
            style: { width: 370 },
            option: {
              listType: "img",
              type: ["jpg", "png"],
              size: "2 mb",
              autoUpload: true,
            },
          },
        ];

        this.productForm = {
          ...this.productForm,
          duration: durationValue, // 购买时长
          userNumber: 1,
          shopNumber: 1,
          possNumber: 1,
        };

        try {
          this.$refs.myForm.$refs.value.clearValidate();
        } catch (error) { }
        this.showForm = true;
        this.isOnePage = true;
      } catch (error) {
        console.log("Errrrrr", error);
      }
    },
    async updateStep(next = false) {
      const clearTimer = () => clearInterval(this.timer);
      if ((this.step === 1 && !next) || (this.step === 4 && next)) {
        this.goBack();
      }
      if (this.step === 1) {
        try {
          await this.$refs.myForm.$refs.value.validate();
          this.step = 2;
        } catch (error) {
          return this.$message.error(Object.values(error)[0][0].message);
        }
      } else if (this.step === 2 && !this.checked && next) {
        return this.$message.warning("请阅读并同意合同条款");
      } else if (this.step === 2 && next) {
        this.$confirm("是否确认付款?", "提示")
          .then(async (res) => {
            const loading = this.$loading({
              lock: true,
              text: "订单提交中...",
              target: document.querySelector(".app-main"),
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.0)",
            });
            // 2 创建订单
            const {
              credentialUrl = "",
              orderMoney = "",
              oldOrderMoney = "",
              payModelId = "",
              productId = "",
              payModel = "",
              shopsPriceId = "",
              usersPriceId = "",
              possPriceId = "",
              shopsQty = "",
              usersQty = "",
              possQty = "",
              isManageShops = true,
              isManageUsers = true,
              isManagePoss = true,
            } = this.orderDetail;

            // 加上时间戳 防止重复
            // orderNo = orderNo + Math.round(new Date())

            try {
              // 提交信息
              let res = await buyOrderData({
                oldOrderMoney,
                productId,
                tenantId: this.tenantId,
                orderMoney,
                payModelId,
                payChannelId: payModel,
                shopsPriceId,
                usersPriceId,
                possPriceId,
                shopsQty: isManageShops ? shopsQty : 0,
                usersQty: isManageUsers ? usersQty : 0,
                possQty: isManagePoss ? possQty : 0,
              });
              let orderNo = res.data;
              this.createdData = {};
              if (payModel == 8) {
                console.log("隼云付");
                // 3 获取支付码
                const imgRes = await orderPay({
                  ordNo: orderNo,
                  amt: orderMoney,
                  orderType: "NO005",
                  subject: "购买商户",
                });
                this.QRImgUrl = imgRes.data;
                console.log("开始4");
                this.step = 3;
                // 每秒检测一次
                this.timer = setInterval(async () => {
                  try {
                    // 一分钟计时
                    if (this.timeing <= 60) {
                      const res = await checkOrderPayState({
                        ordNo: orderNo,
                      });
                      if (res?.data) {
                        // 支付成功
                        clearTimer();
                        this.createdData = {
                          productId: res.data.productId,
                          tenantId: res.data.tenantId,
                        };
                        this.step = 4;
                        this.$message.success("购买成功");
                      }
                    } else {
                      clearTimer();
                      this.$message.warning("支付超时！");
                      this.$emit("update:active", 1);
                    }
                    this.timeing = this.timeing + 1;
                  } catch (error) {
                    clearTimer();
                  }
                }, 1000);
              } else {
                console.log("其他支付");
                this.step = 4;
                this.$message.success("购买成功");
              }
              loading.close();
            } catch (error) {
              return loading.close();
            }
          })
          .catch((err) => {
            this.$message.error("取消支付");
          });
      } else if (this.step > 1 && this.step !== 4 && !next) {
        this.step--;
      }
      // this.step = this.step + (next ? 1 : -1)
      if (this.step !== 3) {
        clearTimer();
      }
    },
    toSP() {
      if (this.createdData?.tenantId && this.createdData?.productId) {
        this.$store.commit("SET_PRODUCTID", this.productId);
      } else {
        this.$router.push("/creation/orderList");
      }
    },
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style lang="scss" scoped>
.buy-step-com {
  width: 100%;
  height: calc(100vh - 84px);
  padding: 0 10px;
  background-color: #eaeaea;

  ::v-deep .my-descriptions-label {
    width: 100px;
    text-align: right;
    display: block;
  }

  ::v-deep .is-process {
    color: #c0c4c0 !important;

    .el-step__icon {
      border: 2px solid #c0c4c0 !important;
    }
  }

  .topBox {
    width: calc(100% - 0px);
    position: fixed;
    z-index: 666;
    background-color: #eaeaea;
  }

  .bottomBox {
    width: 100%;
    padding-top: 173px;
    overflow-y: auto;
  }

  .top-btns {
    width: 100%;
    margin: 10px 0;
  }

  .pay-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;

    .icon {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      margin-right: 3px;
    }

    .QRImgUrl {
      padding: 20px;
      border-radius: 30px;
      overflow: hidden;
      border: 2px solid #b3d9e9;
    }
  }

  .productCardBox {
    border: 1px solid #dedede;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    margin-right: 10px;

    cursor: pointer;

    &:hover {
      border: 1px solid #3490ff;
      box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
    }

    .productCard {
      width: 100%;
      height: 140px;
      text-align: center;

      .priceL {
        font-size: 18px;
      }

      .price {
        font-size: 36px;
        font-weight: bold;
      }

      .priceR {
        font-size: 14px;
        color: #666666;
      }
    }

    .explain {
      font-size: 12px;
      width: 100%;
      height: 40px;
      border-top: 1px solid #dedede;
      bottom: 0;
      padding: 0 20px;
    }
  }

  .productCardBox-on {
    background-color: #edf6ff;
    border: 1px solid #3490ff;
    box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
  }
}

.setMealBox {
  width: 80%;
  padding: 10px 0;
  //border: 1px solid #dedede;
  border-radius: 5px;
}

.setMeal {
  min-width: 240px;
  padding: 8px 10px;
  //border-right: 1px solid #dedede;
  border: 1px solid #dedede;

  &:hover {
    border: 1px solid #3490ff;
    box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
  }
}

.setMealBox-on {
  background-color: #edf6ff;
  border: 1px solid #3490ff;
  box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
}
</style>
