<template>
  <!-- 商户信息明细 -->
  <div class="handleTenantInfoDetail">
    <!-- 顶部按钮 -->
    <top-operating-button
      :showAudit="false"
      :isAuditBillBtn="false"
      @submitForm="save(1)"
      @addBill="save(2)"
      @getQuit="getQuit"
    ></top-operating-button>
    <div class="content">
      <el-form label-width="110px">
        <cardTitleCom cardTitle="基础信息">
          <template slot="cardContent">
            <div class="basicsInfo">
              <div class="row">
                <el-form-item label="商户号">
                  <el-input
                    size="mini"
                    class="w200"
                    disabled
                    v-model="form.tenantNo"
                  ></el-input>
                </el-form-item>
                <el-form-item label="商户名称">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.tenantName"
                  ></el-input>
                </el-form-item>
                <el-form-item label="商户简称">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.simpleName"
                  ></el-input>
                </el-form-item>
                <el-form-item label="商户联系人">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.contactMan"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item label="联系人电话">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.telephone"
                  ></el-input>
                </el-form-item>
                <el-form-item label="邮箱地址">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.email"
                  ></el-input>
                </el-form-item>
                <el-form-item label="身份证号码">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.identityCardNo"
                  ></el-input>
                </el-form-item>
                <el-form-item label="员工数量">
                  <el-input
                    size="mini"
                    class="w200"
                    disabled
                    v-model="form.employeeQty"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item label="商户负责">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.contactMan2"
                  ></el-input>
                </el-form-item>
                <el-form-item label="负责电话">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.telephone2"
                  ></el-input>
                </el-form-item>
                <el-form-item label="邮箱地址">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.email2"
                  ></el-input>
                </el-form-item>
                <el-form-item label="身份证号码">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.identityCardNo2"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item label="商户法人">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.legalMan"
                  ></el-input>
                </el-form-item>
                <el-form-item label="法人电话">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.legalTelephone"
                  ></el-input>
                </el-form-item>
                <el-form-item label="邮箱地址">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.legalEmail"
                  ></el-input>
                </el-form-item>
                <el-form-item label="身份证号码">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.legalIdentityCardNo"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item label="所属行业">
                  <el-input
                    size="mini"
                    class="w200"
                    v-model="form.belongSector"
                  ></el-input>
                </el-form-item>

                <el-form-item label="客户经理">
                  <SelectRemote
                    v-model="form.serviceEmployeeId"
                    :option="
                      $select({
                        key: 'listEmployee'
                      }).option
                    "
                  />
                </el-form-item>
                <el-form-item label="实施人员">
                  <SelectRemote
                    v-model="form.implementEmployeeId"
                    :option="
                      $select({
                        key: 'listEmployee'
                      }).option
                    "
                  />
                </el-form-item>
                <el-form-item label="销售人员">
                  <SelectRemote
                    v-model="form.saleEmployeeId"
                    :option="
                      $select({
                        key: 'listEmployee'
                      }).option
                    "
                  />
                </el-form-item>
                <el-form-item label="商户地址">
                  <el-input
                    size="mini"
                    class="w500"
                    v-model="form.address"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item label="商户logo">
                  <!-- <SingleImageUpload
                    :fileList="tenantLileList"
                    @getImageUrl="handleImageUrl"
                    @delFileItems="deleteLogo"
                    class="w200"
                  /> -->
                  <AccessoryUpload
                    :limit="1"
                    :noneBtnImg="!!form.logoUrl"
                    class="logoWidth"
                    :title="''"
                    listType="picture-card"
                    :fileList="tenantLileList"
                    @getFileItems="handleImageUrl"
                    @delFileItems="deleteLogo"
                  ></AccessoryUpload>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input
                    size="mini"
                    type="textarea"
                    :rows="6"
                    class="w700"
                    v-model="form.remark"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-checkbox v-model="form.isLimitThirdPay"
                    >是否限制第三方支付</el-checkbox
                  >
                </el-form-item>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="多图片上传">
          <template slot="cardContent">
            <div class="morePic">
              <el-form-item label="营业执照" prop="goodsUrl">
                <!-- :noneBtnImg="form.businessUrls != ''" -->
                <AccessoryUpload
                  :title="''"
                  listType="picture-card"
                  :fileList="licenseUrlLileList"
                  @getFileItems="getFileItems"
                  @delFileItems="deleteImg"
                ></AccessoryUpload>
              </el-form-item>
              <el-form-item label="法人证件" prop="goodsUrl">
                <AccessoryUpload
                  :title="''"
                  listType="picture-card"
                  :fileList="legalCertificateUrlList"
                  @getFileItems="legalCertificateFn"
                  @delFileItems="deleteImgLegal"
                ></AccessoryUpload>
              </el-form-item>
              <el-form-item label="其他证件" prop="goodsUrl">
                <AccessoryUpload
                  :title="''"
                  listType="picture-card"
                  :fileList="otherCertificateUrlList"
                  @getFileItems="otherCertificateFn"
                  @delFileItems="deleteImgOther"
                ></AccessoryUpload>
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="更多信息">
          <template slot="cardContent">
            <div class="moreInfo">
              <div class="row">
                <el-form-item label="用户账号:" label-width="120px">
                  <div class="w120">{{ form.userName }}</div>
                </el-form-item>
                <el-form-item label="用户昵称:" label-width="120px">
                  <div class="w120">{{ form.nickName }}</div>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item label="管理员账号:" label-width="120px">
                  <div class="w120">{{ form.adminUserName }}</div>
                </el-form-item>
                <el-form-item label="管理员名称:" label-width="120px">
                  <div class="w120">{{ form.adminUserNickName }}</div>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item label="产品名称:" label-width="120px">
                  <div class="w120">{{ form.productVersion }}</div>
                </el-form-item>
                <el-form-item label="状态:" label-width="120px">
                  {{ setStatus(form.status) }}
                  <!--                  <div class="w120" v-if="form.generationTyp === 0">-->
                  <!--                    创建成功-->
                  <!--                  </div>-->
                  <!--                  <div class="w120" v-if="form.generationTyp === 1">-->
                  <!--                    <div v-if="form.auditStatus === 0">审核</div>-->
                  <!--                    <div v-if="form.auditStatus === 1">审核通过</div>-->
                  <!--                    <div v-if="form.auditStatus === 2">已审核（未通过）</div>-->
                  <!--                  </div>-->
                </el-form-item>
              </div>
              <!--              <div class="row">-->
              <!--                <el-form-item label="支持用户数:" label-width="120px">-->
              <!--                  <div class="w120">{{ form.usableUsers }}</div>-->
              <!--                </el-form-item>-->
              <!--                <el-form-item label="支持门店数:" label-width="120px">-->
              <!--                  <div class="w120">{{ form.usableShops }}</div>-->
              <!--                </el-form-item>-->
              <!--              </div>-->
              <!--              <div class="row">-->
              <!--                <el-form-item label="产品名称:" label-width="120px">-->
              <!--                  <div class="w120">{{ form.productName }}</div>-->
              <!--                </el-form-item>-->
              <!--              </div>-->
              <!--              <div class="row">-->
              <!--                <el-form-item label="产品到期时间:" label-width="120px">-->
              <!--                  <div class="w120">{{ setformatDate(form.validEndTime) }}</div>-->
              <!--                </el-form-item>-->
              <!--                <el-form-item label="产品可用天数:" label-width="120px">-->
              <!--                  <div class="w120">{{ form.usableProductDays }}</div>-->
              <!--                </el-form-item>-->
              <!--              </div>-->
              <!--              <div class="row">-->
              <!--                <el-form-item label="累计购买总天数:" label-width="120px">-->
              <!--                  <div class="w120">{{ form.totalBuyProductDays }}</div>-->
              <!--                </el-form-item>-->
              <!--                <el-form-item label="累计购买总金额:" label-width="120px">-->
              <!--                  <div class="w120">{{ form.totalBuyProductMoney }}</div>-->
              <!--                </el-form-item>-->
              <!--              </div>-->
            </div>

            <el-table
              :data="form.accountProductItems"
              border
              style="width: 100%; margin-bottom: 20px"
              ref="table"
            >
              <el-table-column
                show-overflow-tooltip
                :prop="item.prop"
                :label="item.title"
                :width="item.width"
                v-for="item in tableFields"
                :key="item.title"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span v-if="item.activeText">
                    {{ item.activeText(row) }}
                  </span>
                  <span
                    v-if="item.prop === 'productCalcMode' && !item.activeText"
                    >{{
                      +row[item.prop] === 1 ? '包年包月' : '数量或次数'
                    }}</span
                  >
                  <span
                    v-if="item.prop !== 'productCalcMode' && !item.activeText"
                    >{{ row[item.prop] }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
        </cardTitleCom>
      </el-form>
    </div>
  </div>
</template>

<script>
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import { delFile } from '@/api/tenant/resource/attachFile'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import SingleImageUpload from '@/views/components/singleImageUpload' //单图片上传组件
import AccessoryUpload from '@/views/components/accessoryUpload/index.vue'
import { getTenantEmployee } from '@/api/system/employee.js'
import { saasTenantDetail } from '@/api/tenant/base/info' //多图片上传图片
import { formatDate } from '@/utils/index'
import topOperatingButton from '@/views/components/topOperatingButton'
import { updateTenant } from '@/api/system/tenant' //顶部按钮

export default {
  name: 'HandleTenantInfo',
  components: {
    cardTitleCom,
    SingleImageUpload,
    AccessoryUpload,
    topOperatingButton,
    SelectRemote
  },
  data () {
    return {
      tableFields: [
        { prop: 'belongProductName', title: '所属产品', width: '200' },
        { prop: 'productNo', title: '产品编号', width: '200' },
        { prop: 'productName', title: '产品名称', width: '200' },
        { prop: 'productCalcMode', title: '计费模式', width: '160' },
        {
          prop: 'totalBuyProductMoney',
          title: '累计购买金额(元)',
          width: '160'
        },
        { prop: 'totalBuyProductQty', title: '累计购买数量', width: '160' },
        // { prop: 'totalUseProductQty', title: '累计使用数量', width: '160' },
        { prop: 'usableProductQty', title: '产品可用数量', width: '160' },
        { prop: 'validBegTime', title: '开始时间', width: '155' },
        { prop: 'validEndTime', title: '到期时间', width: '155' }
        // { prop: 'totalBuyProductDays', title: '累计购买天数', width: '160' }
      ],
      form: {
        tenantNo: undefined, //商户号
        simpleName: undefined, //商户简称
        tenantName: undefined, //商户名称
        contactMan: undefined, //商户负责人
        telephone: undefined, //商户负责人电话
        email: undefined, //商户负责人邮箱
        identityCardNo: undefined, //商户负责人身份证
        contactMan2: undefined, //商户联系人
        telephone2: undefined, //商户联系人电话
        email2: undefined, //商户联系人邮箱
        identityCardNo2: undefined, //商户联系人身份证
        legalMan: undefined, //商户法人
        legalTelephone: undefined, //商户法人电话
        legalEmail: undefined, //商户法人邮箱地址
        legalIdentityCardNo: undefined, //商户法人身份证
        employeeQty: undefined, //员工数量
        belongSector: undefined, //所属行业
        address: undefined, //商户地址
        logoUrl: undefined, //商户logo
        remark: undefined, //商户备注
        adminUserName: undefined, // 管理员账号
        adminUserNickName: undefined, // 管理员名称
        generationTyp: undefined, // 生成方式
        totalBuyProductMoney: undefined, // 累计购买总金额
        usableUsers: undefined, // 支持用户数
        usableShops: undefined, // 支持门店数
        productCalcMode: undefined, // 计费模式
        usableProductDays: '', // 产品可用天数
        totalBuyProductDays: '', // 累计购买总天数
        legalCertificateUrl: '', // 商户法人证件
        otherCertificateUrl: '', // 其他证件
        saleEmployeeId: undefined, // 销售经理ID
        serviceEmployeeId: undefined, // 客服人员ID
        businessUrls: [],
        legalUrls: [],
        otherUrls: [],
        // logoUrl: '',
        //上传的附件
        fileItems: [],
        employeeName: ''
        // serviceEmployeeId: undefined,
        // saleEmployeeId: undefined
      },
      tenantId: '', // 商户ID
      type: '', // 操作类型
      tenantLileList: [], // 商户LOGO
      legalCertificateUrlList: [], // 商户法人证件
      otherCertificateUrlList: [], // 其他证件
      licenseUrlLileList: [], // 营业执照
      employeeData: [], //客户经理
      //客户经理
      normalizerEmployee: {
        Id: 'employeeId',
        No: 'employeeNo',
        Name: 'employeeName'
      }
    }
  },
  async created () {
    this.tenantId = this.$route.query.tenantId
    this.type = this.$route.query.type
    console.log('编辑 == ', this.type)
    //客户经理信息
    const res3 = await getTenantEmployee()
    this.employeeData = res3.data
    if (this.type == 'edit') {
      this.getDetail()
    }
  },
  methods: {
    // 设置状态
    setStatus (status) {
      switch (+status) {
        case 0:
          return '正常'
        case -1:
          return '未审核'
        case 1:
          return '停用'
        case 2:
          return '过期'
        case 3:
          return '删除'
      }
    },

    getDetail () {
      saasTenantDetail(this.tenantId).then(res => {
        // this.tenantLileList = [];
        // this.licenseUrlLileList = [];
        // this.otherCertificateUrlList = [];
        // this.legalCertificateUrlList = [];
        this.form = res.data
        this.tenantLileList = this.form.logoUrl
          ? [{ url: this.form.logoUrl }]
          : []
        if (this.form.businessUrls !== []) {
          this.form.businessUrls.forEach(element => {
            this.licenseUrlLileList.push({
              url: element.attachFileUrl
            })
          })
        }
        if (this.form.otherUrls !== []) {
          this.form.otherUrls.forEach(element => {
            this.otherCertificateUrlList.push({
              url: element.attachFileUrl
            })
          })
        }
        if (this.form.legalUrls !== []) {
          this.form.legalUrls.forEach(element => {
            this.legalCertificateUrlList.push({
              url: element.attachFileUrl
            })
          })
        }
      })
    },
    save (num) {
      console.log('保存提交  = ', this.form, num)
      if (num == 1) {
        updateTenant(this.form).then(res => {
          this.$message.success('保存成功')
        })
      } else {
        updateTenant(this.form).then(res => {
          this.$message.success('保存成功')
          this.refresh()
        })
      }
    },
    refresh () {
      this.form = {
        tenantNo: undefined, //商户号
        simpleName: undefined, //商户简称
        tenantName: undefined, //商户名称
        contactMan: undefined, //商户负责人
        telephone: undefined, //商户负责人电话
        email: undefined, //商户负责人邮箱
        identityCardNo: undefined, //商户负责人身份证
        contactMan2: undefined, //商户联系人
        telephone2: undefined, //商户联系人电话
        email2: undefined, //商户联系人邮箱
        identityCardNo2: undefined, //商户联系人身份证
        legalMan: undefined, //商户法人
        legalTelephone: undefined, //商户法人电话
        legalEmail: undefined, //商户法人邮箱地址
        legalIdentityCardNo: undefined, //商户法人身份证
        employeeQty: undefined, //员工数量
        belongSector: undefined, //所属行业
        address: undefined, //商户地址
        remark: undefined, //商户介绍
        saleEmployeeId: undefined, // 销售经理ID
        serviceEmployeeId: undefined, // 客服人员ID
        businessUrls: [],
        legalUrls: [],
        otherUrls: [],
        logoUrl: ''
      }
    },
    setformatDate (time) {
      return formatDate(time)
    },
    //商户logo图片
    handleImageUrl (file) {
      console.log('上传logo成功 = ', file)
      this.form.logoUrl = file.accessUrl
    },
    getFileItems (file) {
      console.log('上传图片：', file)
      this.form.businessUrls.push({
        attachFileUrl: file.accessUrl
      })
    },

    deleteImg (file) {
      console.log('要删除的图片地址 = ', file.url)
      // 找到匹配 file.url 的对象
      const matchingItem = this.form.businessUrls.find(
        item => item.attachFileUrl === file.url
      )

      if (matchingItem) {
        const attachFileId = matchingItem.attachFileId
        // 调用删除图片的请求，并传递 attachFileId 作为参数
        delFile([attachFileId])
          .then(response => {
            // 在删除成功后，从 businessUrls 数组中移除该对象
            this.form.businessUrls = this.form.businessUrls.filter(
              item => item.attachFileUrl !== file.url
            )

            console.log('删除后 == ', this.form.businessUrls)
          })
          .catch(error => {
            // 处理删除失败的情况
            console.error('删除图片时出错:', error)
          })
      } else {
        console.error('未找到匹配的图片对象')
      }
    },

    // 删除法人证件
    deleteImgLegal (file) {
      // 找到匹配 file.url 的对象
      const matchingItem = this.form.legalUrls.find(
        item => item.attachFileUrl === file.url
      )

      if (matchingItem) {
        const attachFileId = matchingItem.attachFileId
        console.log('要删除的法人证件的 attachFileId = ', attachFileId)

        // 调用删除图片的请求，并传递 attachFileId 作为参数
        delFile([attachFileId])
          .then(response => {
            console.log('法人证件删除成功:', response)

            // 在删除成功后，从 legalUrls 数组中移除该对象
            this.form.legalUrls = this.form.legalUrls.filter(
              item => item.attachFileUrl !== file.url
            )

            console.log('删除后 == ', this.form.legalUrls)
          })
          .catch(error => {
            // 处理删除失败的情况
            console.error('删除法人证件时出错:', error)
          })
      } else {
        console.error('未找到匹配的法人证件对象')
      }
    },

    // 删除其他图片
    deleteImgOther (file) {
      // 找到匹配 file.url 的对象
      const matchingItem = this.form.otherUrls.find(
        item => item.attachFileUrl === file.url
      )
      if (matchingItem) {
        const attachFileId = matchingItem.attachFileId
        console.log('要删除的其他图片的 attachFileId = ', attachFileId)
        // 调用删除图片的请求，并传递 attachFileId 作为参数
        delFile([attachFileId])
          .then(response => {
            console.log('其他图片删除成功:', response)
            // 在删除成功后，从 otherUrls 数组中移除该对象
            this.form.otherUrls = this.form.otherUrls.filter(
              item => item.attachFileUrl !== file.url
            )
            console.log('删除后 == ', this.form.otherUrls)
          })
          .catch(error => {
            // 处理删除失败的情况
            console.error('删除其他图片时出错:', error)
          })
      } else {
        console.error('未找到匹配的其他图片对象')
      }
    },

    deleteLogo (file) {
      console.log('logo删除的文件', file)
      this.form.logoUrl = ''
    },

    // 法人证件上传成功
    legalCertificateFn (file) {
      console.log('上传成功---', file)
      this.form.legalUrls.push({
        attachFileUrl: file.accessUrl
      })
    },
    // 其他证件上传成功
    otherCertificateFn (file) {
      this.form.otherUrls.push({
        attachFileUrl: file.accessUrl
      })
    },
    getQuit () {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: '/tenant/base/info' })
    }
  }
}
</script>

<style lang="scss" scoped>
.handleTenantInfoDetail {
  width: 100%;
  padding: 6px;
  padding-top: 1px;
  background-color: #eaeaea;

  // 顶部按钮
  .btnTOP {
    text-align: right;
    margin-bottom: 6px;
    position: fixed;
    margin-top: 0;
    margin-right: 6px;
    padding: 6px 0;
    background-color: #eaeaea;
    width: calc(100% - 162px);
    z-index: 1000;
  }

  //内容区域
  .content {
    margin-top: 34px;
  }

  //基础信息
  .basicsInfo {
    padding: 10px;

    .row {
      display: flex;
      flex-wrap: wrap;
    }

    .w200 {
      width: 200px;
    }

    .logoWidth {
      ::v-deep .el-upload-list__item {
        width: 200px;
        height: 200px;
      }

      ::v-deep .el-upload-list__item-thumbnail {
        height: 200px;
        width: 200px;
      }

      ::v-deep .el-upload-list__item-actions {
        height: 200px;
        width: 200px;
      }

      ::v-deep .el-upload--picture-card {
        height: 200px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .w500 {
      width: 400px;
    }

    .w700 {
      width: 540px;
    }
  }

  //多图片上传
  .morePic {
    padding: 10px;

    ::v-deep .el-upload__tip {
      display: none;
    }
  }

  //更多信息
  .moreInfo {
    padding: 10px;

    .row {
      display: flex;
      flex-wrap: wrap;
    }

    .w120 {
      width: 200px;
    }
  }
}
</style>
