import { render, staticRenderFns } from "./purchase.vue?vue&type=template&id=919b7428&scoped=true"
import script from "./purchase.vue?vue&type=script&lang=js"
export * from "./purchase.vue?vue&type=script&lang=js"
import style0 from "./purchase.vue?vue&type=style&index=0&id=919b7428&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "919b7428",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-pre/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('919b7428')) {
      api.createRecord('919b7428', component.options)
    } else {
      api.reload('919b7428', component.options)
    }
    module.hot.accept("./purchase.vue?vue&type=template&id=919b7428&scoped=true", function () {
      api.rerender('919b7428', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/tenant/base/info/components/purchase.vue"
export default component.exports