<template>
  <!-- 商户列表 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"></TablePage>

    <c-dialog title="商户菜单" :width="500" :showDialog.sync="showMenulistDialog">
      <template #content>
        <div class="cardContent">
          <el-checkbox v-model="menuExpand" @change="
              handleCheckedTreeExpand($event, 'menuOptions', 'menu', 'id')
            ">展开/折叠</el-checkbox>
          <el-checkbox v-model="menuNodeAll"
            @change="handleCheckedTreeNodeAll($event, 'menuOptions', 'menu')">全选/全不选</el-checkbox>
          <el-checkbox v-model="menuCheckStrictly"
            @change="handleCheckedTreeConnect($event, 'menuCheckStrictly')">父子联动</el-checkbox>
          <div class="menuTree">
            <el-scrollbar>
              <el-tree class="tree-border" :data="productMenu.menusTreeItems" show-checkbox ref="menu" node-key="id"
                :check-strictly="!menuCheckStrictly" :default-checked-keys="productMenu.checkedKeys"
                empty-text="暂无数据"></el-tree>
            </el-scrollbar>
          </div>
        </div>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="showMenulistDialog = false">取 消
        </el-button>
        <el-button size="mini" type="primary" @click="MenulistSubmit">确 定
        </el-button>
      </template>
    </c-dialog>

    <c-dialog title="回收站" :width="800" :showDialog.sync="recycleBinDialog">
      <template #content>
        <el-table :data="recycleBinList" style="width: 100%" @selection-change="recycleBinSelectionChange" height="500"
          border>
          <el-table-column align="center" type="selection" width="55">
          </el-table-column>
          <el-table-column align="center" prop="tenantNo" label="商户号" width="180">
          </el-table-column>
          <el-table-column align="center" prop="simpleName" label="商户简称" width="180">
          </el-table-column>
          <el-table-column align="center" prop="productName" label="产品">
          </el-table-column>
          <el-table-column align="center" prop="contactMan" label="商户负责人">
          </el-table-column>
        </el-table>
        <TablePagination :page.sync="dialogQueryParams.pageNum" :limit.sync="dialogQueryParams.pageSize"
          :total="dialogTotal" @pagination="getList"></TablePagination>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="closeRestoreFn">取 消</el-button>
        <el-button size="mini" type="primary" @click="restoreFn">还 原</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  tenantProductStopUsing,
  saasTenantList,
  changeStatusAPI,
  updateProductMenu,
  getProductMenuList
} from '@/api/tenant/base/info'
import TablePage from '@/components/tablePage/index.vue'
import { getTenantEmployee } from '@/api/system/employee'
import { AllTenantAPI } from '@/api/system/tenant'
import { getDicts } from '@/api/system/dict/data'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import { changeTenantStatus } from '@/api/system/tenant'
export default {
  name: 'TenantList',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination,
    CDialog
  },
  dicts: ['tenant_service_state'],
  data() {
    return {
      options: {
        listNo: true,
        mutiSelect: true,
        loading: true,
        check: [],
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          serviceEmployeeId: 1,
          employeeName: undefined, //客户经理
          status: undefined, //商户状态
          telephone: undefined, // 联系人手机号
          query: undefined, // 客户联系人
          tenantName: undefined // 商户
        },
        // 新增自定义合计计算
        customTotal: {
          data: 'list',
          list: [
            {
              title: '总商户数',
              key: 'total',
              value: '0.00'
            }
          ]
        },
        getListApi: saasTenantList,
        listName: ':list',
        title: '商户列表',
        rowKey: 'tenantId',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            clearable: true,
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: '',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          // {
          //   type: 'local',
          //   label: '客户经理',
          //   model: '',
          //   filter: 'contactMan',
          //   seniorSearch: true,
          //   option: {
          //     clearable: true,
          //     remote: getTenantEmployee,
          //     downLoad: 10,
          //     label: 'employeeName',
          //     value: 'employeeId',
          //     filterable: false,
          //     labels: [
          //       { title: '编号', label: 'employeeNo' },
          //       { title: '名称', label: 'employeeName' }
          //     ]
          //   }
          // },
          {
            type: 'local',
            label: '商户',
            model: '',
            filter: 'tenantName',
            seniorSearch: true,
            option: {
              clearable: true,
              remote: AllTenantAPI,
              downLoad: 15,
              label: 'tenantName',
              value: 'tenantName',
              filterable: false,
              labels: [
                { title: '编号', label: 'tenantNo' },
                { title: '名称', label: 'tenantName' }
              ]
            }
          },
          {
            type: 'input',
            tip: '客户联系人',
            seniorSearch: true,
            model: '',
            clearable: true,
            filter: 'query'
          },
          {
            type: 'input',
            tip: '联系人手机号',
            seniorSearch: true,
            model: '',
            clearable: true,
            filter: 'telephone'
          },
          {
            type: 'local',
            label: '商户状态',
            model: '',
            filter: 'status',
            seniorSearch: true,
            option: {
              clearable: true,
              remote: getDicts,
              remoteBody: 'tenant_service_state',
              label: 'dictLabel',
              value: 'dictValue'
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        operates: {
          width: 120,
          list: [
            {
              text: '查看订单',
              method: async row => {
                await this.$router.push({
                  path: '/tenant/product/order',
                  query: {
                    tenantId: row.tenantId
                  }
                })
              }
            }
          ]
        },
        buttons: [
          {
            click: 'start',
            label: '启用',
            num: 1,
            alertText: '确认要启用选中方案吗？',
            btnType: 'dropdown',
            other: [
              {
                click: 'close',
                label: '禁用',
                alertText: '确认要禁用选中方案吗？'
              }
            ]
          },
          {
            click: '续费',
            label: '续费',
            num: 1,
            type: 'primary',
            class: 'icon-goumai iconfont'
          },
          {
            click: '升级',
            label: '升级',
            num: 1,
            type: 'primary',
            class: 'icon-zhixiangshang iconfont'
          },
          {
            click: 'buy',
            label: '购买',
            num: 1,
            type: 'primary',
            class: 'icon-zhixiangshang iconfont'
          },
          {
            click: '完善资料',
            label: '完善资料',
            num: 1,
            type: 'primary',
            class: 'el-icon-document-checked'
          },
          {
            click: '商户菜单',
            label: '商户菜单',
            num: 1,
            type: 'primary',
            class: 'icon-fenlei iconfont'
          },
          // {
          //   click: 'stopAuthorization',
          //   label: '停用授权',
          //   type: 'primary',
          //   alertText: '确认要停用授权选中的产品吗？'
          // },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            btnType: 'dropdown',
            other: [{ click: 'recycle', label: '回收站' }]
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh-right',
            type: ''
          }
        ],
        columns: [
          {
            prop: 'tenantNo',
            type: 'link',
            click: 'link',
            label: '商户号',
            minWidth: '200'
          },
          { prop: 'simpleName', label: '商户简称', minWidth: '200' },
          {
            prop: 'logoUrl',
            type: 'image',
            label: '商户logo',
            minWidth: '100'
          },
          {
            prop: 'status',
            type: 'dicts',
            name: 'tenant_service_state',
            label: '服务状态',
            minWidth: '120'
          },
          { prop: 'validBegTime', label: '开始时间', minWidth: '155' },
          { prop: 'validEndTime', label: '结束时间', minWidth: '155' },
          { prop: 'productName', label: '产品', minWidth: '200' },
          { prop: 'contactMan', label: '商户负责人', minWidth: '150' },
          { prop: 'telephone', label: '负责人电话', minWidth: '150' },
          {
            prop: 'onlineUserCount',
            type: 'link',
            click: 'onlinePopulation',
            label: '在线人数',
            minWidth: '100'
          },
          { prop: 'usableUsers', label: '支持用户数', minWidth: '150' },
          { prop: 'usableShops', label: '支持门店数', minWidth: '150' },
          { prop: 'usablePosss', label: '支持站点数', minWidth: '150' },
          { prop: 'saleAgentName', label: '代理商', minWidth: '120' },
          { prop: 'serviceEmployeeName', label: '客服人员', minWidth: '120' },
          { prop: 'implementEmployeeName', label: '实施人员', minWidth: '120' },
          { prop: 'saleEmployeeName', label: '业务员', minWidth: '120' },
          { prop: 'remark', label: '备注', minWidth: '180' },
          { prop: 'createBy', label: '创建人', minWidth: '100' },
          { prop: 'createTime', label: '创建时间', minWidth: '160' }
        ],
        list: []
      },
      productMenu: [],

      showMenulistDialog: false,

      dialogTotal: 0,
      dialogQueryParams: {
        delFlag: 3,
        pageNum: 1,
        pageSize: 15
      },
      recycleBinDialog: false,
      recycleBinSelect: [],
      recycleBinList: [],

      menuIds: [],

      menuExpand: false,
      menuNodeAll: false,
      menuCheckStrictly: true
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'link':
          this.$router.push(
            `/tenant/base/info/handleTenantInfoDetail?type=edit&tenantId=${row.tenantId}`
          )
          break
        case 'onlinePopulation':
          if (row.onlineUserCount > 0) {
            this.$router.push(
              `/tenant/user/online?type=inquire&tenantId=${row.tenantId}`
            )
          }
          break
        case 'start':
          this.isStatus('0')
          break
        case 'close':
          this.isStatus('1')
          break
        case '续费':
          this.$router.push(
            `/tenant/base/info/handleProduct?tenantId=${this.options.check[0].tenantId}&type=1`
          )
          break
        case '升级':
          if (this.options.check[0].isFinalVersion)
            return this.$message.error('当前版本已是最高级，无法再升级！')
          this.$router.push(
            `/tenant/base/info/upgradation?tenantId=${this.options.check[0].tenantId}`
          )
          break
        case 'buy':
          this.$router.push(
            `/tenant/base/info/purchase?tenantId=${this.options.check[0].tenantId}&productId=${this.options.check[0].productId}`
          )
          break
        case '完善资料':
          const tenantId = this.options.check[0].tenantId
          this.$router.push(
            `/tenant/base/info/handleTenantInfoDetail?type=edit&tenantId=${tenantId}`
          )
          break
        case '商户菜单':
          getProductMenuList({
            productId: this.options.check[0].productId,
            tenantId: this.options.check[0].tenantId
          }).then(res => {
            this.productMenu = res.data
            this.showMenulistDialog = true
          })
          break
        case 'del':
          const ids = this.options.check.map(x => x.tenantId)
          this.$modal
            .confirm('是否确认删除选中的数据项？')
            .then(() => {
              return changeTenantStatus(ids, 3)
            })
            .then(() => {
              this.$modal.msgSuccess('删除成功')
              this.$refs.tablePage.getList()
            })
            .catch(() => {
              console.error('删除失败')
            })
          break
        case 'recycle':
          this.getList()
          break
        case 'stopAuthorization':
          try {
            let statusNo = this.options?.check
              ?.map(item => {
                if (item.status == 0) {
                  return {
                    productId: item.productId,
                    tenantId: item.tenantId
                  }
                }
              })
              .filter(item => item != undefined)
            if (statusNo.length <= 0) {
              return this.$message.success('请选择状态为正常的商户进行停用')
            }
            await tenantProductStopUsing(statusNo)
            await this.$refs.tablePage.getList()
            this.$message.success('停用成功')
          } catch (error) { }
          break
        default:
          break
      }
    },

    /** 查询表格列表 */
    async getList() {
      // 检查 queryParams.telephone 是否为 null 或 undefined，如果是则设置为空字符串
      saasTenantList(this.dialogQueryParams).then(res => {
        this.recycleBinList = res.data.list
        this.dialogTotal = res.data.total
        this.recycleBinDialog = true
      })
    },

    // 启用/禁用
    isStatus(status) {
      let text = status === '0' ? '启用' : '停用'
      // 根据选中的商户ID查找对应的商户数据
      const ids = this.options.check
        .map(item => item.tenantId)
        .filter(item => item != undefined)
      const selectedTenants = this.options.list.filter(item =>
        ids.includes(item.tenantId)
      )
      const tenantNames = selectedTenants.map(tenant => tenant.tenantName)
      if (tenantNames.length > 0) {
        this.$modal
          .confirm(`确认要"${text}"${tenantNames.join(', ')}"商户吗？`)
          .then(() => {
            // 继续处理启用/禁用逻辑
            changeStatusAPI({
              tenantIds: ids,
              status: status
            }).then(res => {
              this.$message.success('操作成功')
            })
          })
          .catch(() => {
            // 用户取消了操作
          })
      }
    },

    // 商户菜单提交
    MenulistSubmit() {
      // 目前被选中的菜单节点
      this.menuIds = this.$refs.menu.getCheckedKeys()
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys()
      this.menuIds.unshift.apply(this.menuIds, halfCheckedKeys)
      updateProductMenu({
        menuIds: this.menuIds,
        productId: this.options.check[0].productId,
        tenantId: this.options.check[0].tenantId
      }).then(res => {
        this.$message.success('更新成功')
        this.showMenulistDialog = false
        this.$refs.tablePage.getList()
      })
    },

    // 还原
    restoreFn() {
      changeTenantStatus(this.recycleBinSelect, 0).then(res => {
        this.$message.success('还原成功')
        this.recycleBinDialog = false
        this.$refs.tablePage.getList()
      })
    },
    // 回收站选择的数据
    recycleBinSelectionChange(val) {
      this.recycleBinSelect = val.map(x => x.tenantId)
    },
    // 关闭回收站弹框
    closeRestoreFn() {
      this.recycleBinDialog = false
    },

    //tree（展开/折叠）
    handleCheckedTreeExpand(value, arrName, refName, id) {
      let treeList = this.productMenu.menusTreeItems
      for (let i = 0; i < treeList.length; i++) {
        this.$refs[refName].store.nodesMap[treeList[i][id]].expanded = value
      }
    },
    // tree（全选/全不选）
    handleCheckedTreeNodeAll(value, arrName, refName) {
      this.$refs[refName].setCheckedNodes(
        value ? this.productMenu.menusTreeItems : []
      )
    },
    // tree（父子联动）
    handleCheckedTreeConnect(value, fieldName) {
      this[fieldName] = !!value
    }
  }
}
</script>
<style lang="scss" scoped>
.menuTree {
  max-height: 500px;
  overflow: auto;
}
</style>
