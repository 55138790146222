<!-- 升级 -->
<template>
  <div id="buyStepComBox" class="buy-step-com" v-loading="loading">
    <div id="topBoxH" class="topBox y-start" :style="{ width: `${topBoxWidth}px` }">
      <div class="top-btns x-c-end">
        <el-button size="mini" v-if="[1, 2, 3].includes(step)" @click="updateStep()">上一步</el-button>
        <el-button size="mini" v-if="[1, 2].includes(step)" @click="updateStep(true)">下一步</el-button>
        <el-button size="mini" v-if="step === 4" @click="updateStep(true)">完成</el-button>
      </div>

      <cardTitleCom cardTitle="当前进度" style="width: 100%; margin-bottom: 0px">
        <template slot="cardContent">
          <div class="padd10">
            <el-steps :active="step" size="mini" align-center class="step">
              <el-step v-for="title in [
                  '选择产品规格',
                  '确认订单',
                  '去支付',
                  '支付成功',
                ]" :key="title" :title="title"></el-step>
            </el-steps>
          </div>
        </template>
      </cardTitleCom>
    </div>

    <div class="bottomBox" :style="{ height: `${tableHeight}px` }">
      <cardTitleCom cardTitle="产品规格" v-show="step === 1" style="margin-bottom: 0">
        <template slot="cardContent">
          <div style="margin: 10px">
            <div class="x-w setMealBox marL30" style="width: 100%">
              <div class="x-bc setMeal cursorP" v-for="(item, index) in product" :key="index"
                :class="[index === active ? 'setMealBox-on' : '']" @click="updateForm('active', index)">
                <div>
                  <b>{{ item.productName }}</b>
                </div>
                <div>
                  <b v-if="
                      durationIndex >= 0 &&
                      item.payModelItems &&
                      item.payModelItems[durationIndex] &&
                      item.payModelItems[durationIndex].residueMoney > 0
                    ">
                    ￥{{ item.payModelItems[durationIndex].residueMoney }}</b>
                  <b v-else-if="item.payModelItems">￥{{ item.payModelItems[0].residueMoney }}</b>
                  <b v-else>免费</b>
                </div>
              </div>
            </div>
            <myForm label-position="left-label" v-if="showForm" v-model="productForm" ref="myForm"
              :options="productFormOption" label-width="120px" />
          </div>
        </template>
      </cardTitleCom>

      <div v-show="step === 2" v-if="orderDetail">
        <cardTitleCom cardTitle="产品规格">
          <template slot="cardContent">
            <el-descriptions :column="1" labelClassName="my-descriptions-label">
              <el-descriptions-item label="服务名称">
                {{
                  `${product[active].parentProductName}（${productData[active].productName}）`
                }}
              </el-descriptions-item>
              <el-descriptions-item label="规格">
                <span v-if="productForm.userNumber > 0">{{ productForm.userNumber }}个员工账户</span>
                <span v-if="productForm.shopNumber > 0">
                  <span v-if="productForm.userNumber > 0">+</span>{{ productForm.shopNumber }}个门店数</span>
                <span v-if="productForm.possNumber > 0">
                  <span v-if="
                      productForm.userNumber > 0 || productForm.shopNumber > 0
                    ">+</span>{{ productForm.possNumber }}个站点数</span>
              </el-descriptions-item>
              <el-descriptions-item label="有效期">{{
                orderDetail.unit
              }}</el-descriptions-item>
              <el-descriptions-item label="原价">{{ Number(orderDetail.oldOrderMoney).toFixed(2) }}元
              </el-descriptions-item>
              <el-descriptions-item label="优惠券">无可用优惠券</el-descriptions-item>
              <el-descriptions-item label="合计">{{ Number(orderDetail.orderMoney).toFixed(2) }}元
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="产品规格">
          <template slot="cardContent">
            <div class="padd10">
              <el-checkbox v-model="checked"></el-checkbox>
              <span style="font-size: 12px; margin-left: 5px; line-height: 23px">
                我已阅读并同意
                <el-link type="primary" style="font-size: 12px">《合同条款》</el-link>
              </span>
              <div style="height: 80px"></div>
            </div>
          </template>
        </cardTitleCom>
      </div>

      <cardTitleCom cardTitle="支付方式" v-show="step === 3" v-if="orderDetail">
        <template slot="cardContent">
          <div class="pay-code">
            <div style="line-height: 30px; padding: 15px 0; font-size: 16px">
              应付金额：
              <span style="font-size: 30px; color: #ff5b28">{{ orderDetail.orderMoney }}元</span>
            </div>
            <img :src="QRImgUrl" class="QRImgUrl" style="width: 180px; height: 180px" />
            <div style="
                color: #666666;
                font-size: 14px;
                padding: 15px 0;
                display: flex;
                algin-item: center;
                justify-content: center;
              ">
              <el-image :src="require('@/assets/images/weixinpay.png')" class="icon" />
              <div>微信支付</div>
              <el-image :src="require('@/assets/images/zhifubaopay.png')" class="icon" />
              <div>支付宝支付</div>
              <el-image :src="require('@/assets/images/yunshanpay.png')" class="icon" />
              <div>云闪付</div>
            </div>
            <div style="color: #666666; font-size: 14px; padding: 15px 0">
              温馨提示：产品一经激活启用，非产品原因不允许退货退款
            </div>
          </div>
        </template>
      </cardTitleCom>

      <cardTitleCom :cardTitle="+$route.query.type === 1 ? '续费状态' : '升级状态'" v-if="step === 4">
        <div class="pay-code" slot="cardContent">
          <i class="el-icon-circle-check" style="color: #28ac3f; font-size: 48px; margin: 15px"></i>
          {{ +$route.query.type === 1 ? "续费成功" : "升级成功" }}
          <div>
            <el-link type="primary" @click="goBack">返回</el-link>
          </div>
        </div>
      </cardTitleCom>
    </div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import myForm from "@/components/my-form";
import {
  getMakeUpForDifferences,
  getProductUserShopQty,
  upgradeProduct,
} from "@/api/tenant/base/info";
import { orderPay, checkOrderPayState } from "@/api/creation";
import { payConfigList } from "@/api/tenant/product/order";
import { mapMutations } from "vuex"; //选择短信产品弹窗
export default {
  name: "buyStep",
  components: { swiper, swiperSlide, cardTitleCom, myForm },
  props: ["inProductId"],
  data() {
    return {
      active: 0, //
      dateActive: 0, // 时长
      showForm: false,
      QRImgUrl: "",
      checked: false,
      loading: true,
      step: 1,
      payImg: "",
      productFormOption: [],
      productForm: {},
      dictRes: [],
      productData: [],
      timeing: 0, // 监控之间
      timer: null, // 支付状态计时器
      yearsData: [],
      createdData: null,
      timeA: null, // 定时器 - 定时跳转
      type: undefined, // 1: 续费  2：升级
      tenantId: undefined, // 操作的商户ID
      product: [],
      shopsTableSelect: [], // 选择续费的门店
      usersTableSelect: [], // 选择续费的用户
      possTableSelect: [], // 选择续费的站点
      topBtnBoxWidth: 0,
      vivwH: 0,
      topBoxH: 0,
      payList: [],
      differences: "", // 升级折扣价object

      shopProductItem: [], // 门店
      userProductItem: [], // 用户
      possProductItem: [], // 站点
      durationIndex: undefined,
    };
  },
  computed: {
    isRenew() {
      return Number(this.$route?.query?.type) === 1;
    },
    tableHeight() {
      return this.vivwH;
    },
    topBoxWidth() {
      this.$nextTick(() => {
        this.topBtnBoxWidth =
          document.getElementById("buyStepComBox").clientWidth;
      });
      return this.topBtnBoxWidth - 20;
    },
    // 计算用户订详情
    orderDetail() {
      const oneKey = "duration";
      const twoKey = "userNumber";
      const threeKey = "shopNumber";
      const possKey = "possNumber";

      const oneData = this.yearsData;
      const val = this.productForm;
      const {
        label,
        productId: pdID,
        payModelId: pmID,
        initializeUsers,
        initializeShops,
        initializePoss,
        saasProductUsersPrices,
        saasProductShopsPrices,
        saasProductPossPrices,
        initializeShopsPrice,
        initializeUsersPrice,
        initializePossPrice,
      } = oneData?.find((x) => x.value === val[oneKey]) || {};
      this.productForm.money = "0" + "元";
      if (!this.productForm[oneKey] || !pdID) {
        return null;
      }
      let money =
        this.productData[this.active].payModelItems[this.durationIndex]
          ?.residueMoney || 0; // 订单价格 + 产品价格
      let oldMoney =
        this.productData[this.active].payModelItems[this.durationIndex]
          ?.productOldPrice || 0; // 订单原价 + 产品原价 前端用
      let unit = ""; // 单位 前端用
      let payModelId = null; // 包年付费方式ID
      let productId = null; // 购买产品ID
      let shopsPriceId = null; // 门店数价格ID
      let usersPriceId = null; // 用户数价格ID
      let possPriceId = null; // 站点数价格ID

      let usersOldMoney = 0; // 用户原价  前端用
      let usersMoney = 0; // 用户支付价格  前端用
      let showUsersPrice = 0; // 用户展示的单价

      let shopsOldMoney = 0; // 门店原价  前端用
      let shopsMoney = 0; // 门店支付价格  前端用
      let showShopPrice = 0; // 门店展示的单价

      let possOldMoney = 0; // 站点原价  前端用
      let possMoney = 0; // 站点支付价格  前端用
      let showPossPrice = 0; // 站点展示的单价
      // 购买时常价格

      if (label && pdID) {
        unit = label;
        productId = pdID;
        payModelId = pmID;
      } else {
        return null;
      }

      // 购买人数价格
      if (saasProductUsersPrices?.[0]?.usersPrice) {
        let twoData = saasProductUsersPrices?.sort?.(
          (a, b) => b.users - a.users
        );
        const {
          usersPrice = 0,
          usersOldPrice = 0,
          usersPriceId: upId,
        } = twoData?.find((x) => val[twoKey] >= x.users) || {};

        /*
         * this.productForm[twoKey] 当前选中的用户数
         * usersOldPrice   用户单价
         * */
        usersOldMoney = usersOldPrice * this.productForm[twoKey]; // 用户原价  前端用
        usersMoney = (usersPrice || usersOldPrice) * this.productForm[twoKey]; // 用户支付价格  前端用
        showUsersPrice = usersPrice; // 用来展示的用户单价
        // console.log('产品价格', productPrice)
        // console.log('原价', productOldPrice) //原价
        // console.log('用户支付价格', usersMoney)

        if (this.userProductItem?.length > 0) {
          //用户数价格
          money =
            money +
            this.userProductItem.reduce(
              (total, obj) => total + obj.residueMoney,
              0
            );
        }

        // console.log(oldMoney, usersOldMoney, usersOldPrice)
        oldMoney = oldMoney + usersOldMoney;
        usersPriceId = upId;
      }
      console.log("oldMoney3", oldMoney);
      // 购买门店价格
      if (saasProductShopsPrices?.[0]?.shopsPrice) {
        let threeData = saasProductShopsPrices?.sort?.(
          (a, b) => b.shops - a.shops
        );
        const {
          shopsPrice = 0,
          shopsOldPrice = 0,
          shopsPriceId: spId,
        } = threeData?.find((x) => val[threeKey] >= x.shops) || {};
        /*
         * this.productForm[twoKey] 当前选中的用户数
         * usersOldPrice   用户单价
         * */
        shopsOldMoney = shopsOldPrice * this.productForm[threeKey]; // 用户原价  前端用
        shopsMoney = (shopsPrice || shopsOldPrice) * this.productForm[threeKey]; // 用户支付价格  前端用

        showShopPrice = shopsPrice; // 用于显示提示
        if (this.shopProductItem?.length > 0) {
          //用户数价格+门店数价格
          money =
            money +
            this.shopProductItem.reduce(
              (total, obj) => total + obj.residueMoney,
              0
            );
        }

        // console.log(oldMoney, shopsOldMoney, shopsOldPrice)
        oldMoney = oldMoney + shopsOldMoney;
        shopsPriceId = spId;
      }
      console.log("oldMoney2", oldMoney);
      // 购买站点价格
      if (saasProductPossPrices?.[0]?.possPrice) {
        let threeData = saasProductPossPrices?.sort?.(
          (a, b) => b.poss - a.poss
        );
        const {
          possPrice = 0,
          possOldPrice = 0,
          possPriceId: ppId,
        } = threeData?.find((x) => val[possKey] >= x.poss) || {};
        /*
         * this.productForm[twoKey] 当前选中的用户数
         * usersOldPrice   用户单价
         * */
        possOldMoney = possOldPrice * this.productForm[possKey] || 0; // 用户原价  前端用
        console.log(
          "oldMoney1.5",
          possOldMoney,
          possOldPrice,
          this.productForm[possKey],
          this.productForm,
          possKey
        );
        possMoney = (possPrice || possOldPrice) * this.productForm[possKey]; // 用户支付价格  前端用

        showPossPrice = possPrice; // 用于显示提示
        if (this.possProductItem?.length > 0) {
          //用户数价格+门店数价格
          money =
            money +
            this.possProductItem.reduce(
              (total, obj) => total + obj.residueMoney,
              0
            );
        }
        oldMoney = oldMoney + possOldMoney;
        possPriceId = ppId;
      }
      console.log("oldMoney1", oldMoney);
      // 去下浮点
      money =
        +this.$route.query.type === 2
          ? this.differences.totalDifferencesOrderMoney || 0
          : money;

      money = money % 1 === 0 ? money : Number(money.toFixed(2));
      oldMoney = oldMoney % 1 === 0 ? oldMoney : Number(oldMoney.toFixed(2));
      // money = money + Number(productPrice) // 按数量：续费时不在计算产品价格
      console.log("oldMoney", oldMoney);
      this.productForm.money = money + "元";
      const obj = {
        ...val,
        orderMoney: money,
        oldOrderMoney: oldMoney,
        unit,
        payModelId,
        productId,
        shopsPriceId,
        usersPriceId,
        possPriceId,
        shopsQty: val[threeKey], //门店数
        usersQty: val[twoKey], //  用户数
        possQty: val[possKey], //  站点数
        usersOldMoney,
        usersMoney,
        shopsOldMoney,
        shopsMoney,
        possOldMoney,
        possMoney,
        initializeUsers,
        initializeShops,
        initializePoss,
        showUsersPrice,
        showShopPrice,
        showPossPrice,
        linkManTel: String(val.linkManTel),
      };

      this.QRImgUrl = "";

      return obj;
    },
  },
  created() {
    this.tenantId = this.$route.query.tenantId;
    this.getPayList();
  },
  async mounted() {
    try {
      const { data } = await this.getDicts("product_years_package_mode");
      this.dictRes = data;

      /* if (+this.$route.query.type === 2) {

      } else {
        let res = await getTenantProductToRenew({
          tenantId: this.tenantId,
          productBookDetailIds: this.$route.query.ids.split(',')
        })
        this.product = res.data
        this.productData = res.data
        await this.updateForm()
      } */
      let num = await getProductUserShopQty({
        tenantId: this.tenantId,
      });
      this.product = num.data;
      this.productData = num.data;
      this.shopProductItem = num.data[0]?.shopProductItem;
      this.userProductItem = num.data[0]?.userProductItem;
      this.possProductItem = num.data[0]?.possProductItem;
      // console.log('num', num.data)
      await this.updateForm();

      this.$nextTick(() => {
        this.topBtnBoxWidth =
          document.getElementById("buyStepComBox").clientWidth;
        this.vivwH = document.getElementById("buyStepComBox").clientHeight;
        this.topBoxH = document.getElementById("topBoxH").clientHeight;
        window.addEventListener("resize", this.handleResize);
        this.$refs.myForm?.$refs.shopsTableRef?.[0].toggleAllSelection();
        this.$refs.myForm?.$refs.usersTableRef?.[0].toggleAllSelection();
        this.$refs.myForm?.$refs.possTableRef?.[0].toggleAllSelection();
      });
      this.loading = false;
    } catch (error) { }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    "productForm.duration": {
      async handler(newDuration) {
        if (this.showForm && newDuration) {
          this.durationIndex = this.productData[
            this.active
          ].payModelItems.findIndex((x) => x.payModelType === newDuration);

          let num = await getProductUserShopQty({
            tenantId: this.tenantId,
            payModelId:
              this.productData[this.active].payModelItems[this.durationIndex]
                .payModelId,
          });
          this.shopProductItem = num.data[this.active]?.shopProductItem || [];
          this.userProductItem = num.data[this.active]?.userProductItem || [];
          this.possProductItem = num.data[this.active]?.possProductItem || [];
          await this.updateForm();
        }
      },
    },
    usersTableSelect(nVal) {
      if (nVal.length >= 1) {
        this.productForm.userNumber = nVal.length;
      }
    },
    shopsTableSelect(nVal) {
      if (nVal.length >= 1) {
        this.productForm.shopNumber = nVal.length;
      }
    },
    possTableSelect(nVal) {
      if (nVal.length >= 1) {
        this.productForm.possNumber = nVal.length;
      }
    },
  },
  methods: {
    ...mapMutations("tagsView", ["DEL_VISITED_VIEW"]),

    // 返回上一页
    goBack() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/tenant/base/info" });
    },

    handleResize() {
      this.topBtnBoxWidth =
        document.getElementById("buyStepComBox").clientWidth;
      this.vivwH = document.getElementById("buyStepComBox").clientHeight;
      this.topBoxH = document.getElementById("topBoxH").clientHeight;
    },

    // 获取支付
    getPayList() {
      payConfigList().then((res) => {
        this.payList = res.rows;
      });
    },

    async updateForm(key, value) {
      if (key == "active") {
        this.durationIndex = 0;
      }
      if (value != undefined) {
        this.shopProductItem = this.product[value].shopProductItem;
        this.userProductItem = this.product[value].userProductItem;
      }
      if (key) {
        this[key] = value;
      }
      try {
        this.showForm = false;
        const {
          payModelItems,
          maxUsers,
          maxShops,
          productPrice,
          productOldPrice,
          productId,
        } = this.productData[this.active];

        // 升级折扣价
        this.differences = "";
        if (this.$route.query.type === 2) {
          this.differences = (
            await getMakeUpForDifferences({
              productId,
              tenantId: this.tenantId,
              payModelId: payModelItems[this.duration || 0].payModelId,
              shopsPriceId:
                payModelItems[this.duration || 0].saasProductShopsPrices[0]
                  ?.shopsPriceId || "",
              usersPriceId:
                payModelItems[this.duration || 0].saasProductUsersPrices[0]
                  ?.usersPriceId || "",
            })
          ).data;
        }

        this.yearsData = payModelItems.map((x) => {
          const item = this.dictRes.find((y) => y.dictValue === x.payModelType);
          return {
            ...x,
            value: x.payModelType,
            label: item.dictLabel,
            productPrice,
            productOldPrice,
          };
        });
        const {
          initializeShopsPrice,
          initializeUsersPrice,
          initializePossPrice,
          saasProductUsersPrices,
          saasProductShopsPrices,
          saasProductPossPrices,
          value: durationValue,
        } = this.yearsData[this.durationIndex ? this.durationIndex : 0];
        const userShops = [
          // saasProductUsersPrices?.[0]?.usersPrice && userProductItem.length > 0 ? {
          this.userProductItem?.length > 0
            ? {
              model: "userNumber",
              type: "input-number",
              label: "在线用户数",
              append: {
                type: "xyt-users",
                getPrice: () => ({
                  unit: this.orderDetail.unit,
                  // showUsersPrice: this.orderDetail.showUsersPrice,
                  // orderMoney: this.orderDetail.usersMoney, //多少钱一年
                  // oldOrderMoney:
                  //   this.orderDetail.usersOldMoney >
                  //   this.orderDetail.usersMoney
                  //     ? this.orderDetail.usersOldMoney
                  //     : '',
                  discounted:
                    this.differences !== ""
                      ? this.differences.differencesUserOrderMoney || 0
                      : undefined,
                }),
              },
              data: saasProductUsersPrices,
              min: this.userProductItem?.length || 0,
              max: maxUsers,
              initializeUsers: this.userProductItem?.length || 0,
              initializeUsersPrice: initializeUsersPrice,
              disabled: true,
              eltableCheck: false,
              precision: 0,
              rules: [
                {
                  required: true,
                  message: "请输入在线用户数",
                  trigger: ["blur", "change"],
                },
              ],
            }
            : null,
          // saasProductUsersPrices?.[0]?.usersPrice && userProductItem.length > 0 ? {
          this.userProductItem?.length > 0
            ? {
              model: "ProductUsersTable",
              type: "table",
              ref: "usersTableRef",
              eltableCheck: true,
              option: {
                tableData: this.userProductItem,
                tableHand: [
                  { prop: "tenantNo", label: "商户号", width: 120 },
                  { prop: "lineSn", label: "用户系列号", width: 130 },
                  { prop: "residueMoney", label: "补差金额", width: 120 },
                  { prop: "validBegTime", label: "有效期开始", width: 160 },
                  { prop: "validEndTime", label: "有效期结束", width: 160 },
                ],
                handleSelectionChange: (val) => {
                  if (val.length > 0) {
                    this.usersTableSelect = val;
                  } else if (val.length < 1) {
                    this.$refs.myForm.$refs.usersTableRef?.[0].toggleRowSelection(
                      this.usersTableSelect[0],
                      true
                    );
                  }
                },
              },
            }
            : null,
          // saasProductShopsPrices?.[0]?.shopsPrice && shopProductItem.length > 0 ? {
          this.shopProductItem?.length > 0
            ? {
              model: "shopNumber",
              type: "input-number",
              label: "门店个数",
              data: saasProductShopsPrices,
              min: this.shopProductItem?.length || 0,
              max: maxShops,
              initializeShops: this.shopProductItem?.length || 0,
              initializeShopsPrice: initializeShopsPrice,
              disabled: true,
              eltableCheck: false,
              precision: 0,
              append: {
                type: "xyt-users",
                getPrice: () => ({
                  unit: this.orderDetail.unit,
                  // orderMoney: this.orderDetail.shopsMoney,
                  // oldOrderMoney:
                  //   this.orderDetail.shopsOldMoney >
                  //   this.orderDetail.shopsMoney
                  //     ? this.orderDetail.shopsOldMoney
                  //     : '',
                  discounted:
                    this.differences !== ""
                      ? this.differences.differencesShopOrderMoney || 0
                      : undefined,
                }),
              },
            }
            : null,
          // saasProductShopsPrices?.[0]?.shopsPrice && shopProductItem.length > 0 ? {
          this.shopProductItem?.length > 0
            ? {
              model: "ProductShopsTable",
              type: "table",
              ref: "shopsTableRef",
              eltableCheck: true,
              option: {
                tableData: this.shopProductItem,
                tableHand: [
                  { prop: "tenantNo", label: "商户号", width: 120 },
                  { prop: "lineSn", label: "门店系列号", width: 130 },
                  { prop: "residueMoney", label: "补差金额", width: 120 },
                  { prop: "validBegTime", label: "有效期开始", width: 160 },
                  { prop: "validEndTime", label: "有效期结束", width: 160 },
                ],
                handleSelectionChange: (val) => {
                  if (val.length > 0) {
                    this.shopsTableSelect = val;
                  } else if (val.length < 1) {
                    this.$refs.myForm.$refs.shopsTableRef?.[0].toggleRowSelection(
                      this.shopsTableSelect[0],
                      true
                    );
                  }
                },
              },
            }
            : null,
          this.possProductItem?.length > 0
            ? {
              model: "possNumber",
              type: "input-number",
              label: "站点个数",
              data: saasProductPossPrices,
              min: this.possProductItem?.length || 0,
              max: maxShops,
              initializePoss: this.possProductItem?.length || 0,
              initializePossPrice: initializePossPrice,
              disabled: true,
              eltableCheck: false,
              precision: 0,
              append: {
                type: "xyt-users",
                getPrice: () => ({
                  unit: this.orderDetail.unit,
                  discounted:
                    this.differences !== ""
                      ? this.differences.differencesShopOrderMoney || 0
                      : undefined,
                }),
              },
            }
            : null,

          this.possProductItem?.length > 0
            ? {
              model: "ProductPossTable",
              type: "table",
              ref: "possTableRef",
              eltableCheck: true,
              option: {
                tableData: this.possProductItem,
                tableHand: [
                  { prop: "tenantNo", label: "商户号", width: 120 },
                  { prop: "lineSn", label: "站点系列号", width: 130 },
                  { prop: "residueMoney", label: "补差金额", width: 120 },
                  { prop: "validBegTime", label: "有效期开始", width: 160 },
                  { prop: "validEndTime", label: "有效期结束", width: 160 },
                ],
                handleSelectionChange: (val) => {
                  if (val.length > 0) {
                    this.possTableSelect = val;
                  } else if (val.length < 1) {
                    this.$refs.myForm.$refs.possTableRef?.[0].toggleRowSelection(
                      this.possTableSelect[0],
                      true
                    );
                  }
                },
              },
            }
            : null,
        ];

        this.productFormOption = [
          {
            model: "duration",
            type: "radio-button",
            label: "购买时长",
            rules: [
              {
                required: true,
                message: "请选择购买时长",
                trigger: ["blur", "change"],
              },
            ],
            optionData: this.yearsData,
          },
          ...userShops.filter((x) => x),
          {
            model: "money",
            type: "text-append",
            label: "合计",
            readonly: true,
          },
          {
            type: "divider",
          },
          {
            label: "支付方式",
            seniorSearch: true,
            type: "local",
            model: "payModel",
            filter: "payModeId",
            addNum: 2,
            width: "220px",
            option: {
              data: this.payList,
              dataKey: "rows",
              label: "payModeName",
              value: "payModeId",
              filterable: true,
            },
            rules: [
              {
                required: true,
                message: "请选择支付方式",
                trigger: ["blur", "change"],
              },
            ],
          },
          {
            prop: "credentialUrl",
            model: "credentialUrl",
            type: "upload-one",
            label: "支付凭证",
            style: { width: 370 },
            option: {
              listType: "img",
              type: ["jpg", "png"],
              size: "2 mb",
              autoUpload: true,
            },
          },
        ];

        this.productForm = {
          ...this.productForm,
          duration: durationValue, // 购买时长
          userNumber: this.userProductItem?.length || 0,
          shopNumber: this.shopProductItem?.length || 0,
          possNumber: this.possProductItem?.length || 0,
        };

        try {
          this.$refs.myForm.$refs.value.clearValidate();
        } catch (error) { }
        this.showForm = true;
      } catch (error) {
        console.log("Errrrrr", error);
      }
    },
    async updateStep(next = false) {
      const clearTimer = () => clearInterval(this.timer);
      if ((this.step === 1 && !next) || (this.step === 4 && next)) {
        this.goBack();
      }
      if (this.step === 1) {
        try {
          await this.$refs.myForm.$refs.value.validate();
          this.step = 2;
        } catch (error) {
          return this.$message.error(Object.values(error)[0][0].message);
        }
      } else if (this.step === 2 && !this.checked && next) {
        return this.$message.warning("请阅读并同意合同条款");
      } else if (this.step === 2 && next) {
        this.$confirm("是否确认付款?", "提示")
          .then(async (res) => {
            const loading = this.$loading({
              lock: true,
              text: "订单提交中...",
              target: document.querySelector(".app-main"),
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.0)",
            });
            // 2 创建订单
            const {
              credentialUrl = "",
              orderMoney = "",
              oldOrderMoney = "",
              payModelId = "",
              productId = "",
              payModel = "",
              shopsPriceId = "",
              usersPriceId = "",
              possPriceId = "",
              shopsQty = "",
              usersQty = "",
              possQty = "",
            } = this.orderDetail;

            // // 加上时间戳 防止重复
            // orderNo = orderNo + Math.round(new Date())

            let userProductItem = this.usersTableSelect.map(
              (x) => x.productBookDetailId
            );
            let shopProductItem = this.shopsTableSelect.map(
              (x) => x.productBookDetailId
            );
            let possProductItem = this.possTableSelect.map(
              (x) => x.productBookDetailId
            );
            try {
              // 提交信息
              let res = await upgradeProduct({
                oldOrderMoney,
                possProductItem,
                shopProductItem,
                userProductItem,
                productId,
                tenantId: this.tenantId,
                orderMoney,
                payModelId,
                payChannelId: payModel,
                shopsPriceId,
                usersPriceId,
                possPriceId,
                shopsQty,
                usersQty,
                possQty,
              });
              let orderNo = res.data;
              this.createdData = {};
              if (payModel == 8) {
                console.log("隼云付");
                // 3 获取支付码
                const imgRes = await orderPay({
                  ordNo: orderNo,
                  amt: orderMoney,
                  orderType: "NO005",
                  subject: "升级商户",
                });
                this.QRImgUrl = imgRes.data;
                console.log("开始4");
                this.step = 3;
                // 每秒检测一次
                this.timer = setInterval(async () => {
                  try {
                    // 一分钟计时
                    if (this.timeing <= 60) {
                      const res = await checkOrderPayState({
                        ordNo: orderNo,
                      });
                      if (res?.data) {
                        // 支付成功
                        clearTimer();
                        this.createdData = {
                          productId: res.data.productId,
                          tenantId: res.data.tenantId,
                        };
                        this.step = 4;
                        this.$message.success("升级成功");
                      }
                    } else {
                      clearTimer();
                      this.$message.warning("支付超时！");
                      this.$emit("update:active", 1);
                    }
                    this.timeing = this.timeing + 1;
                  } catch (error) {
                    clearTimer();
                  }
                }, 1000);
              } else {
                console.log("其他支付");
                this.step = 4;
                this.$message.success("升级成功");
              }
              loading.close();
            } catch (error) {
              return loading.close();
            }
          })
          .catch((err) => {
            this.$message.error("取消支付");
          });
      } else if (this.step > 1 && this.step !== 4 && !next) {
        this.step--;
      }
      // this.step = this.step + (next ? 1 : -1)
      if (this.step !== 3) {
        clearTimer();
      }
    },
    toSP() {
      if (this.createdData?.tenantId && this.createdData?.productId) {
        // switchTenant({ tenantId: this.createdData.tenantId }).then(res => {
        this.$store.commit("SET_PRODUCTID", this.productId);
        //     this.$store.commit('SET_TENANTID', this.createdData.tenantId)
        //     this.$store.commit('RESET_ROUTES')
        //     this.$tab.closeAllPage()
        //     this.$router.push('/index')
        // })
      } else {
        this.$router.push("/creation/orderList");
      }
    },
  },
  destroyed() {
    clearTimeout(this.timeA);
  },
};
</script>
<style lang="scss" scoped>
.buy-step-com {
  width: 100%;
  height: calc(100vh - 84px);
  padding: 0 10px;
  background-color: #eaeaea;

  ::v-deep .my-descriptions-label {
    width: 100px;
    text-align: right;
    display: block;
  }

  ::v-deep .is-process {
    color: #c0c4c0 !important;

    .el-step__icon {
      border: 2px solid #c0c4c0 !important;
    }
  }

  .topBox {
    width: calc(100% - 0px);
    position: fixed;
    z-index: 666;
    background-color: #eaeaea;
  }

  .bottomBox {
    width: 100%;
    padding-top: 173px;
    overflow-y: auto;
  }

  .top-btns {
    width: 100%;
    margin: 10px 0;
  }

  .pay-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;

    .icon {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      margin-right: 3px;
    }

    .QRImgUrl {
      padding: 20px;
      border-radius: 30px;
      overflow: hidden;
      border: 2px solid #b3d9e9;
    }
  }

  .productCardBox {
    border: 1px solid #dedede;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    margin-right: 10px;

    cursor: pointer;

    &:hover {
      border: 1px solid #3490ff;
      box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
    }

    .productCard {
      width: 100%;
      height: 140px;
      text-align: center;

      .priceL {
        font-size: 18px;
      }

      .price {
        font-size: 36px;
        font-weight: bold;
      }

      .priceR {
        font-size: 14px;
        color: #666666;
      }
    }

    .explain {
      font-size: 12px;
      width: 100%;
      height: 40px;
      border-top: 1px solid #dedede;
      bottom: 0;
      padding: 0 20px;
    }
  }

  .productCardBox-on {
    background-color: #edf6ff;
    border: 1px solid #3490ff;
    box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
  }
}

.setMealBox {
  width: 80%;
  padding: 10px 0;
  //border: 1px solid #dedede;
  border-radius: 5px;
}

.setMeal {
  min-width: 240px;
  padding: 8px 10px;
  //border-right: 1px solid #dedede;
  border: 1px solid #dedede;

  &:hover {
    border: 1px solid #3490ff;
    box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
  }
}

.setMealBox-on {
  background-color: #edf6ff;
  border: 1px solid #3490ff;
  box-shadow: 0px 3px 20px 1px rgba(51, 142, 255, 0.15);
}
</style>
