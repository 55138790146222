<template>
    <!-- 重置密码 -->
    <div class="resetPWDialog">
        <c-dialog
            title="密码重置"
            :showDialog.sync="openDialog"
        >
            <template #content>
                <el-form :model="form" label-width="80px" :rules="rules" ref="form">
                    <el-form-item label="新密码" prop="newPassword">
                        <el-input v-model="form.newPassword" placeholder="请输入新密码"/>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="confirmPW">
                        <el-input v-model="form.confirmPW" placeholder="请确认密码"/>
                    </el-form-item>
                </el-form>
            </template>
            <template #buttonList>
                    <el-button @click="handleClose">取 消</el-button>
                    <el-button type="primary" @click="submitPW">确 定</el-button>
            </template>v>
        </c-dialog>
    </div>
</template>

<script>
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
export default {
    name: 'ResetPWDialog',
    components:{
        CDialog
    },
    props: {
        //弹窗开关
        openDialog: {
            type: Boolean,
            default: false
        },
        //id
        id: {
            type: Number
        },
        //密码表单
        form: {
            type: Object
        }
    },
    data() {
        //自定义校验规则
        let confirmPW = (role, value, callback) => {
            if (value.length < 6) {
                callback(new Error('密码长度不能少于6位'))
            } else if (value !== this.form.newPassword) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        }
        return {
            //校验规则
            rules: {
                newPassword: [
                    { required: true, trigger: 'blur', message: '请输入新密码' }
                ],
                confirmPW: [
                    { required: true, trigger: 'blur', message: '请确认新密码' },
                    { validator: confirmPW, trigger: ['blur', 'change'] }
                ]
            }
        }
    },
    methods: {
        handleClose() {
            this.$emit('update:openDialog', false)
        },
        //提交密码
        submitPW() {
            this.$refs['form'].validate(async valid => {
                //表单校验
                if (valid) {
                    this.$emit('handleResetPW', this.form.newPassword)
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>

</style>
