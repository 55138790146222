<template>
    <div class="employeeEditDialog">
        <c-dialog
            :title="title"
            :width="500"
            :showDialog.sync="openDialog"
        >
            <template #content>
                <el-form label-width="85px" :model="form" :rules="rules" ref="form">
                    <el-form-item label="员工编号" class="w50" prop="userName">
                        <el-input v-model="form.userName" placeholder="请输入员工账号"/>
                    </el-form-item>
                    <el-form-item label="员工姓名" class="w50" prop="employeeName">
                        <el-input
                            v-model="form.employeeName"
                            placeholder="请输入员工姓名"
                        />
                    </el-form-item>
                    <el-form-item label="性别" class="w50" prop="sex">
                        <el-select
                            v-model="form.sex"
                            placeholder="请选择性别"
                            clearable
                            @blur="$refs.form.validateField('sex')"
                        >
                            <el-option
                                v-for="dict in dict.type.sys_user_sex"
                                :key="dict.value"
                                :label="dict.label"
                                :value="dict.value"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属部门" class="w50" prop="deptId">
                        <treeselect
                            v-model="form.deptId"
                            class="treeselect"
                            :options="deptList"
                            :show-count="true"
                            placeholder="请选择部门"
                            noChildrenText="暂无数据"
                            noOptionsText="暂无数据"
                            @blur="treeVerify"
                        />
                    </el-form-item>
                    <el-form-item label="手机号" class="w50" prop="telephone">
                        <el-input v-model="form.telephone" placeholder="请输入账号" disabled/>
                    </el-form-item>
                    <el-form-item label="邮箱" class="w50" prop="email">
                        <el-input v-model="form.email" placeholder="请输入邮箱"/>
                    </el-form-item>
                    <el-form-item label="身份证号码" class="w50" prop="identityCardNo">
                        <el-input
                            v-model="form.identityCardNo"
                            placeholder="请输入身份证号码"
                        />
                    </el-form-item>

                    <el-form-item label="学历" class="w50" prop="diploma">
                        <el-input v-model="form.diploma" placeholder="请输入学历"/>
                    </el-form-item>
                    <el-form-item label="所在省市区" class="w50" prop="regionId">
                        <el-cascader
                            ref="cascader"
                            class="provinceListClass"
                            :options="provinceList"
                            :props="defaultProp"
                            v-model="form.regionId"
                            maxlength="80"
                            @change="getAddressId"
                            emitPath
                        >
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="详细地址" class="w100" prop="address">
                        <el-input v-model="form.address" placeholder="请输入详细地址"/>
                    </el-form-item>
                </el-form>
            </template>
            <template #buttonList>
                <el-button size="mini" @click="handleClose">取 消
                </el-button>
                <el-button size="mini" type="primary" @click="handleEditEmployee">确 定
                </el-button>
            </template>
        </c-dialog>
    </div>
</template>

<script>
import { getData } from '@/utils/public'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { sysDeptList } from '@/api/system/dept'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'

export default {
    name: 'EmployeeEditDialog',
    props: {
        openDialog: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object
        }
    },
    dicts: ['sys_user_sex'],
    components: { Treeselect, CDialog },
    watch: {
        openDialog: {
            async handler(val) {
                if (val) {
                    this.loading = true
                    if (this.provinceList.length <= 0) this.provinceList = await getData('AllRegion') //行政区数据
                    let res = await sysDeptList({
                        accountId: this.form.accountId,
                        tenantId: this.form.tenantId
                    }) //部门数据
                    this.deptList = res.data
                    this.loading = false
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            loading: false,
            title: '修改员工信息',
            provinceList: [], //省市区数据
            deptList: [], //部门数据
            //行政地址类型转换
            defaultProp: {
                value: 'id',
                label: 'label'
            },
            // 表单校验
            rules: {
                userName: [
                    { required: true, message: '员工账号不能为空', trigger: 'blur' }
                ],
                employeeName: [
                    { required: true, message: '员工姓名不能为空', trigger: 'blur' }
                ],
                sex: [
                    {
                        required: true,
                        message: '性别不能为空',
                        trigger: ['change', 'blur']
                    }
                ],
                deptId: [
                    {
                        required: true,
                        message: '所属部门不能为空',
                        trigger: ['change', 'blur']
                    }
                ]
                // telephone: [
                //   { required: true, message: '手机号不能为空', trigger: 'blur' }
                // ]
            }
        }
    },
    async created() {
    },
    methods: {
        //所属部门tree下拉组件单独校验事件
        treeVerify() {
            this.$nextTick(() => {
                this.$refs.form.validateField('deptId')
            })
        },
        //省市区名字/省市区id
        getAddressId() {
            this.$set(
                this.form,
                'regionId',
                this.$refs['cascader'].getCheckedNodes()[0].data.id
            )
            this.$set(
                this.form,
                'regionName',
                this.$refs['cascader'].getCheckedNodes()[0].pathLabels.join('')
            )
        },
        //弹窗关闭的回调
        handleClose() {
            this.$emit('update:openDialog', false)
        },
        //编辑员工
        handleEditEmployee() {
            this.$refs['form'].validate(async valid => {
                //表单校验
                if (valid) {
                    this.$emit('editEmployee')
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
    max-width: 800px !important;
    min-width: 800px !important;
}
//
//::v-deep .el-dialog__body {
//    padding: 10px 20px 0px !important;
//}


.el-form {
    display: flex;
    flex-wrap: wrap;
}

.w50 {
    width: 50%;
}

.w100 {
    width: 100%;
}

.el-cascader,
.el-input,
.el-select {
    width: 100%;
}

.btn {
    text-align: right;
    padding: 10px 0;
}


</style>
