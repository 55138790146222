<template>
  <!-- 产品公告 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"></TablePage>

    <handleNoticeDialog :openDialog.sync="openHandleNoticeDialog" @getList="getList" :curNoticeId="curNoticeId" />
  </div>
</template>

<script>
import TablePagination from "@/components/tablePage/tablePagination";
import seniorSearch from "@/views/components/seniorSearch"; //高级搜索
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import TablePage from "@/components/tablePage/index.vue";
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import {
  noticeList,
  isStopNotice,
  removeNotice,
  copyNotice,
  noticeStatus,
  restoreNotice
} from "@/api/tenant/base/notice";
import handleNoticeDialog from "@/views/tenant/base/notice/components/handleNoticeDialog.vue";

export default {
  name: "noticeList",
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination,
    CDialog,
    handleNoticeDialog,
  },
  dicts: ["sys_notice_type"],
  data() {
    return {
      options: {
        listNo: true,
        mutiSelect: true,
        loading: true,
        check: [],
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
        },
        getListApi: noticeList,
        title: "商户列表",
        rowKey: "noticeId",
        buttons: [
          {
            click: "add",
            label: "新增",
            type: "primary",
            icon: "el-icon-plus",
          },
          {
            click: "start",
            label: "启用",
            alertText: "确认要启用选中产品公告吗？",
            btnType: "dropdown",
            other: [
              {
                click: "close",
                label: "禁用",
                alertText: "确认要禁用选中产品公告吗？",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "copy",
            label: "复制",
            type: "success",
            icon: "el-icon-document-copy",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "fabu",
            label: "发布",
            type: "success",
            disabled: () => !this.options?.check?.length,
            alertText: "确认要发布选中产品公告吗？",
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            // alertText: '确认要删除选中的产品公告吗？',
            btnType: 'dropdown',
            trigger: 'click', // 要求
            other: [
              {
                label: '回收站',
                collectionDialog: {
                  restoreListApi: restoreNotice,
                  dcollectionBody: {
                    delFlag: '1'
                  }
                }
              }
            ]
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh-right",
            type: "",
          },
        ],
        columns: [
          {
            prop: "noticeTitle",
            type: "link",
            click: "link",
            label: "公告标题",
          },
          { prop: "noticeContent", type: "html", label: "公告内容" },
          {
            prop: "noticeType",
            type: "dicts",
            status: "noticeType",
            tag: "warning",
            name: "sys_notice_type",
            label: "公告类型",
          },
          { prop: "createBy", label: "创建人" },
          { prop: "createTime", label: "创建时间" },
          {
            prop: "isStop",
            activeText(row) {
              return row.isStop ? "禁用" : "启用";
            },
            label: "是否启用",
          },
          {
            prop: "noticeStatus",
            activeText(row) {
              return row.noticeStatus == 2 ? "发布" : '未发布';
            },
            label: "发布状态",
          },
        ],
        list: [],
      },
      openHandleNoticeDialog: false,
      curNoticeId: undefined,
    };
  },
  methods: {
    getList() {
      this.$refs.tablePage.getList();
    },

    async isStatus(type) {
      let noticeIds = [];
      // 是否停用（停用 true，启用 false）
      this.options.check.forEach((item) => {
        if (type === "启用" && item.isStop) noticeIds.push(item.noticeId);
        if (type === "禁用" && !item.isStop) noticeIds.push(item.noticeId);
      });
      console.log('noticeIds', noticeIds);
      if (noticeIds.length >= 1) await isStopNotice({ isStop: type === "启用" ? false : true, noticeIds });
      this.$message.success(`${type}成功`);
      await this.$refs.tablePage.getList();
    },

    async handleEvent(type, row) {
      switch (type) {
        case "add":
          this.curNoticeId = undefined;
          this.openHandleNoticeDialog = true;
          break;
        case "link":
          this.curNoticeId = row.noticeId;
          this.openHandleNoticeDialog = true;
          break;
        case "start":
          await this.isStatus("启用");
          break;
        case "close":
          await this.isStatus("禁用");
          break;
        case "fabu":
          let noticeIds = [];
          this.options.check.forEach((item) => {
            if (item.noticeStatus != 2) noticeIds.push(item.noticeId);
          });
          if (noticeIds.length >= 1)
            await noticeStatus({
              noticeIds,
              noticeStatus: 2,
            });
          this.$message.success(`发布成功`);
          await this.$refs.tablePage.getList();
          break;
        case "copy":
          let ids = this.options.check.map((item) => item.noticeId);
          await copyNotice(ids);
          this.$message.success(`复制成功`);
          break;
        case "del":
          let check = this.options.check.filter(v => v.isStop)
          if (check.length == 0) return this.$message.error('请选择禁用的公告')
          const name = check.map((item) => item.noticeTitle);
          this.$confirm(`确定删除${name.join(",")}公告`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              let ids = this.options.check.map((item) => item.noticeId);
              await removeNotice(ids);
              this.$message.success("删除成功");
              await this.$refs.tablePage.getList();
            })
            .catch(() => { });
          break;
      }
    },
  },
};
</script>