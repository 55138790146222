<template>
  <!-- 新增/编辑商户 -->
  <div class="handleTenantInfoDialog">
    <el-dialog
      :title="title"
      :visible.sync="openDialog"
      v-dialogDrag="true"
      width="50%"
      :before-close="handleClose"
    >
      <div class="content">
        <el-form label-width="110px">
          <el-form-item label="商户号" class="w50">
            <el-input v-model="form.vipNo" disabled="" />
          </el-form-item>
          <el-form-item label="商户名称" class="w50">
            <el-input v-model="form.vipNo" placeholder="请输入商户名称" />
          </el-form-item>
          <el-form-item label="商户简称" class="w50">
            <el-input v-model="form.vipNo" placeholder="请输入商户简称" />
          </el-form-item>
          <el-form-item label="商户联系人" class="w50">
            <el-input v-model="form.vipNo" placeholder="请输入商户联系人" />
          </el-form-item>
          <el-form-item label="商户联系人电话" class="w50">
            <el-input v-model="form.vipNo" placeholder="请输入商户联系人电话" />
          </el-form-item>
          <el-form-item label="邮箱地址" class="w50">
            <el-input v-model="form.vipNo" placeholder="请输入邮箱地址" />
          </el-form-item>
          <el-form-item label="商户地址" prop="regionId" class="address">
            <div class="x-f">
              <el-cascader
                                class="provinceListClass"
                                :options="provinceList"
                                :props="defaultProp"
                                v-model="form.regionId"
                                maxlength="80"
                                filterable
                                clearable
                                placeholder="请选择省市区"
                            >
                            </el-cascader>
               <el-input v-model="form.vipNo" placeholder="请输入详细地址" />
            </div>
                           
                        </el-form-item>
          <el-form-item label="产品信息" class="w50">
            <el-select v-model="form.value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="付款方式" class="w50">
            <el-select v-model="form.value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户经理" class="w50">
            <el-input v-model="form.vipNo" placeholder="请输入客户经理" />
          </el-form-item>
          <el-form-item label="官网域名" class="w50">
            <el-input v-model="form.vipNo" placeholder="请输入官网域名" />
          </el-form-item>
          <el-form-item label="商户logo">
             <SingleImageUpload />
          </el-form-item>
          <el-form-item label="营业执照">
            <SingleImageUpload />
          </el-form-item>
        </el-form>
        <div class="btn">
            <el-button  @click="handleClose">取 消</el-button>
            <el-button type="primary">确 定</el-button>
        </div>
      </div>
      
    </el-dialog>
  </div>
</template>

<script>
import SingleImageUpload from '@/views/components/singleImageUpload'  //单图片上传组件
import { getData } from '@/utils/public'
export default {
  name: 'HandleTenantInfoDialog',
  props: {
    openDialog: {
      type: Boolean,
      default: false
    }
  },
  components:{ SingleImageUpload },
  async created () {
    this.provinceList = await getData('AllRegion')
  },
  data () {
    return {
      title: '新增商户',
      form: {},
      options: [],
      provinceList: [], //省市区数据
      //行政地址类型转换
      defaultProp: {
        value: 'id',
        label: 'label'
      }
    }
  },
  methods: {
    handleClose () {
      this.$emit('update:openDialog', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.handleTenantInfoDialog {
  ::v-deep .el-dialog{
        max-width: 850px !important;
        min-width: 850px !important;
    }
    ::v-deep .el-dialog__body{
        padding: 0 20px !important;
    }
    .el-input,.el-select{
    width: 100%;
  }
  .address{
    width: 100%;
    .el-cascader{
    width: 30%;
  }
  .el-input{
    width: calc(70% - 5px);
    margin-left: 5px;
  }
  }
 
  .el-form {
    display: flex;
    flex-wrap: wrap;
  }
  .w50 {
    width: 50%;
  }
  .btn{
        text-align: right;
        padding: 10px 0;
    }
}
</style>
