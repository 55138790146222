var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "buy-step-com",
      attrs: { id: "buyStepComBox" },
    },
    [
      _c(
        "div",
        {
          staticClass: "topBox y-start",
          style: { width: _vm.topBoxWidth + "px" },
          attrs: { id: "topBoxH" },
        },
        [
          _c(
            "div",
            { staticClass: "top-btns x-c-end" },
            [
              [1, 2, 3].includes(_vm.step)
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.updateStep()
                        },
                      },
                    },
                    [_vm._v("上一步")]
                  )
                : _vm._e(),
              [1, 2].includes(_vm.step)
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.updateStep(true)
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  )
                : _vm._e(),
              _vm.step === 4
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.updateStep(true)
                        },
                      },
                    },
                    [_vm._v("完成")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "cardTitleCom",
            {
              staticStyle: { width: "100%", "margin-bottom": "0px" },
              attrs: { cardTitle: "当前进度" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "padd10" },
                  [
                    _c(
                      "el-steps",
                      {
                        staticClass: "step",
                        attrs: {
                          active: _vm.step,
                          size: "mini",
                          "align-center": "",
                        },
                      },
                      _vm._l(
                        ["选择产品规格", "确认订单", "去支付", "支付成功"],
                        function (title) {
                          return _c("el-step", {
                            key: title,
                            attrs: { title: title },
                          })
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottomBox", style: { height: _vm.tableHeight + "px" } },
        [
          _c(
            "cardTitleCom",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step === 1,
                  expression: "step === 1",
                },
              ],
              staticStyle: { "margin-bottom": "0" },
              attrs: { cardTitle: "产品规格" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticStyle: { margin: "10px" } },
                  [
                    _vm.showForm
                      ? _c("myForm", {
                          ref: "myForm",
                          attrs: {
                            "label-position": "left-label",
                            options: _vm.productFormOption,
                            "label-width": "120px",
                          },
                          model: {
                            value: _vm.productForm,
                            callback: function ($$v) {
                              _vm.productForm = $$v
                            },
                            expression: "productForm",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _vm.orderDetail
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 2,
                      expression: "step === 2",
                    },
                  ],
                },
                [
                  _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "产品规格" } },
                    [
                      _c(
                        "template",
                        { slot: "cardContent" },
                        [
                          _c(
                            "el-descriptions",
                            {
                              attrs: {
                                column: 1,
                                labelClassName: "my-descriptions-label",
                              },
                            },
                            [
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "服务名称" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.product[_vm.active]
                                          .parentProductName +
                                          "（" +
                                          _vm.productData[_vm.active]
                                            .productName +
                                          "）"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "规格" } },
                                [
                                  _vm.productForm.userNumber > 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.productForm.userNumber) +
                                            "个员工账户"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.productForm.shopNumber > 0
                                    ? _c("span", [
                                        _vm.productForm.userNumber > 0
                                          ? _c("span", [_vm._v("+")])
                                          : _vm._e(),
                                        _vm._v(
                                          _vm._s(_vm.productForm.shopNumber) +
                                            "个门店数"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.productForm.possNumber > 0
                                    ? _c("span", [
                                        _vm.productForm.userNumber > 0 ||
                                        _vm.productForm.shopNumber > 0
                                          ? _c("span", [_vm._v("+")])
                                          : _vm._e(),
                                        _vm._v(
                                          _vm._s(_vm.productForm.possNumber) +
                                            "个站点数"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "有效期" } },
                                [_vm._v(_vm._s(_vm.orderDetail.unit))]
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "原价" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      Number(
                                        _vm.orderDetail.oldOrderMoney
                                      ).toFixed(2)
                                    ) + "元 "
                                  ),
                                ]
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "优惠券" } },
                                [_vm._v("无可用优惠券")]
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "合计" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      Number(
                                        _vm.orderDetail.orderMoney
                                      ).toFixed(2)
                                    ) + "元 "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "cardTitleCom",
                    { attrs: { cardTitle: "产品规格" } },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "padd10" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.checked,
                                callback: function ($$v) {
                                  _vm.checked = $$v
                                },
                                expression: "checked",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  "margin-left": "5px",
                                  "line-height": "23px",
                                },
                              },
                              [
                                _vm._v(" 我已阅读并同意 "),
                                _c(
                                  "el-link",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { type: "primary" },
                                  },
                                  [_vm._v("《合同条款》")]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticStyle: { height: "80px" } }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.orderDetail
            ? _c(
                "cardTitleCom",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 3,
                      expression: "step === 3",
                    },
                  ],
                  attrs: { cardTitle: "支付方式" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c("div", { staticClass: "pay-code" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "line-height": "30px",
                            padding: "15px 0",
                            "font-size": "16px",
                          },
                        },
                        [
                          _vm._v(" 应付金额： "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "30px",
                                color: "#ff5b28",
                              },
                            },
                            [_vm._v(_vm._s(_vm.orderDetail.orderMoney) + "元")]
                          ),
                        ]
                      ),
                      _c("img", {
                        staticClass: "QRImgUrl",
                        staticStyle: { width: "180px", height: "180px" },
                        attrs: { src: _vm.QRImgUrl },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#666666",
                            "font-size": "14px",
                            padding: "15px 0",
                            display: "flex",
                            "algin-item": "center",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c("el-image", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/weixinpay.png"),
                            },
                          }),
                          _c("div", [_vm._v("微信支付")]),
                          _c("el-image", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/zhifubaopay.png"),
                            },
                          }),
                          _c("div", [_vm._v("支付宝支付")]),
                          _c("el-image", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/yunshanpay.png"),
                            },
                          }),
                          _c("div", [_vm._v("云闪付")]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#666666",
                            "font-size": "14px",
                            padding: "15px 0",
                          },
                        },
                        [
                          _vm._v(
                            " 温馨提示：产品一经激活启用，非产品原因不允许退货退款 "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm.step == 4
            ? _c(
                "cardTitleCom",
                {
                  attrs: {
                    cardTitle:
                      +_vm.$route.query.type == 1 ? "续费状态" : "升级状态",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pay-code",
                      attrs: { slot: "cardContent" },
                      slot: "cardContent",
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-check",
                        staticStyle: {
                          color: "#28ac3f",
                          "font-size": "48px",
                          margin: "15px",
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$route.query.type == 1 ? "续费成功" : "升级成功"
                          ) +
                          " "
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.goBack },
                            },
                            [_vm._v("返回")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }