var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resetPWDialog" },
    [
      _c(
        "c-dialog",
        {
          attrs: { title: "密码重置", showDialog: _vm.openDialog },
          on: {
            "update:showDialog": function ($event) {
              _vm.openDialog = $event
            },
            "update:show-dialog": function ($event) {
              _vm.openDialog = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-width": "80px",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "新密码", prop: "newPassword" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入新密码" },
                            model: {
                              value: _vm.form.newPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "newPassword", $$v)
                              },
                              expression: "form.newPassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "确认密码", prop: "confirmPW" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请确认密码" },
                            model: {
                              value: _vm.form.confirmPW,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "confirmPW", $$v)
                              },
                              expression: "form.confirmPW",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "buttonList",
              fn: function () {
                return [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submitPW } },
                    [_vm._v("确 定")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v("v> ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }