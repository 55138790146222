<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      v-show="showSearch"
      label-width="130px"
    >
      <el-form-item label="商户名称" prop="tenantName">
        <el-input
          v-model="queryParams.tenantName"
          placeholder="请输入商户名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item label="商户号" prop="tenantNo">
        <el-input
          v-model="queryParams.tenantNo"
          placeholder="请输入商户号"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item label="联系人" prop="contactMan">
        <el-input
          v-model="queryParams.contactMan"
          placeholder="请输入商户联系人"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item label="联系电话" prop="telephone">
        <el-input
          v-model="queryParams.telephone"
          placeholder="请输入商户联系电话"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item label="服务状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="请选择服务状态"
          clearable
        >
          <el-option
            v-for="dict in dict.type.sys_normal_disable"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['system:tenant:edit']"
          >修改</el-button
        >
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['system:tenant:remove']"
          >删除</el-button
        >
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['system:tenant:export']"
          >导出</el-button
        >
      </el-col>
      <right-toolbar
        :showSearch.sync="showSearch"
        @queryTable="getList"
      ></right-toolbar>
    </el-row>

    <el-table
      v-loading="loading"
      :data="tenantList"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" align="center" width="55" />
      <el-table-column
        label="商户名称"
        align="center"
        prop="tenantName"
        width="260"
      >
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="handleUpdateTenant(scope.row)"
            type="text"
            >{{ scope.row.tenantName }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        label="商户简称"
        align="center"
        prop="simpleName"
        width="180"
      />
      <el-table-column
        label="商户号"
        align="center"
        prop="tenantNo"
        width="180"
      />
      <el-table-column
        label="商户管理员"
        align="center"
        prop="userName"
        width="180"
      />
      <el-table-column
        label="联系人"
        align="center"
        prop="contactMan"
        width="120"
      />
      <el-table-column
        label="联系电话"
        align="center"
        prop="telephone"
        width="120"
      />
      <el-table-column
        label="邮箱地址"
        align="center"
        prop="email"
        width="180"
      />
      <el-table-column
        label="联系地址"
        align="center"
        prop="address"
        width="220"
      />
      <el-table-column
        label="官网域名"
        align="center"
        prop="domain"
        width="260"
      />

      <!-- 自定义样式隐藏内容(超过3行自动隐藏) -->
      <el-table-column
        label="商户介绍"
        align="center"
        prop="introduction"
        width="180"
      >
        <template slot-scope="scope">
          <el-tooltip :introduction="scope.row.introduction" placement="top">
            <p class="limitContent">{{ scope.row.introduction }}</p>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
        label="单位LOGO"
        align="center"
        prop="logoUrl"
        width="220"
      >
        <template slot-scope="scope">
          <img
            style="width: 80px; object-fit: cover; height: 80px"
            @click="showBigImage(scope.row.logoUrl)"
            :src="scope.row.logoUrl"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="营业执照"
        align="center"
        prop="licenseUrl"
        width="220"
      >
        <template slot-scope="scope">
          <img
            style="width: 80px; object-fit: cover; height: 80px"
            @click="showBigImage(scope.row.licenseUrl)"
            :src="scope.row.licenseUrl"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="服务状态"
        align="center"
        prop="status"
        key="status"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            active-value="0"
            inactive-value="1"
            @change="handleStatusChange(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:tenant:edit']"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:tenant:remove']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改商户基本信息对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="180px">
        <el-form-item label="商户名称" prop="tenantName">
          <el-input v-model="form.tenantName" placeholder="请输入商户名称" />
        </el-form-item>

        <el-form-item label="商户简称" prop="simpleName">
          <el-input v-model="form.simpleName" placeholder="请输入商户简称" />
        </el-form-item>

        <el-form-item label="联系人" prop="contactMan">
          <el-input v-model="form.contactMan" placeholder="请输入商户联系人" />
        </el-form-item>

        <el-form-item label="联系电话" prop="telephone">
          <el-input v-model="form.telephone" placeholder="请输入商户联系电话" />
        </el-form-item>

        <el-form-item label="邮箱地址" prop="email">
          <el-input v-model="form.email" placeholder="请输入商户邮箱地址" />
        </el-form-item>

        <el-form-item label="联系地址" prop="address">
          <el-input v-model="form.address" placeholder="请输入商户联系地址" />
        </el-form-item>

        <el-form-item label="官网域名" prop="domain">
          <el-input v-model="form.domain" placeholder="请输入商户官网域名" />
        </el-form-item>

        <el-form-item label="商户介绍" prop="introduction">
          <el-input
            v-model="form.introduction"
            type="textarea"
            placeholder="请输入内容"
          />
        </el-form-item>

        <el-form-item label="商户LOGO" prop="logoUrl">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleLogoSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.logoUrl" :src="form.logoUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="营业执照" prop="licenseUrl">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleLicenseUrlSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.licenseUrl" :src="form.licenseUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogImageVisible" width="30%">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import {
  listTenant,
  getTenant,
  delTenant,
  addTenant,
  updateTenant,
  changeTenantStatus
} from '@/api/system/tenant'
export default {
  name: 'Tenant',
  dicts: ['sys_normal_disable'],
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/open/oss/file/upload',
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 商户基本信息表格数据
      tenantList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,

      // 图片放大可见属性设置
      dialogImageVisible: false,

      // 图片放大url属性设置
      dialogImageUrl: null,

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        tenantName: null,
        simpleName: null,
        status: null,
        contactMan: null,
        telephone: null,
        email: null
      },
      // 表单参数
      form: {
        tenantName: null,
        simpleName: null,
        status: 0,
        contactMan: null,
        telephone: null,
        email: null,
        address: null,
        domain: null,
        introduction: null,
        logoUrl: null,
        licenseUrl: null
      },
      // 表单校验
      rules: {
        tenantName: [
          { required: true, message: '商户名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    //切换其他商户
    async handleUpdateTenant (row) {
      const tenantName = row.tenantName //获取商户名称
      const productId = row.productId //获取商户产品id
      const tenantId = row.tenantId //获取商户id
      this.$confirm(`确定切换到${tenantName}商户?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.commit('RESET_ROUTES') //清空当前商户路由
          this.$store.commit('SET_TENANTID', '') //清空当前商户id
          this.$store.commit('SET_PRODUCTID', '') //清空当前商户产品id
          this.$store.commit('SET_PRODUCTID', productId) //存入产品id
          this.$store.commit('SET_TENANTID', tenantId) //存入商户id
          this.$router.push('/index') //重新跳转首页
          this.$message({
            type: 'success',
            message: '切换商户成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },

    /** 查询商户基本信息列表 */
    getList () {
      this.loading = true
      listTenant(this.queryParams).then(response => {
        this.tenantList = response.rows
        this.total = response.total
        this.loading = false
      })
    },

    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },

    // 表单重置
    reset () {
      this.form = {
        tenantId: null,
        tenantName: null,
        simpleName: null,
        status: 0,
        contactMan: null,
        telephone: null,
        email: null,
        address: null,
        domain: null,
        introduction: null,
        logoUrl: null,
        licenseUrl: null
      }
      this.resetForm('form')
    },

    /** 上传LOGO图片方式 */
    handleLogoSuccess (res, file) {
      this.form.logoUrl = file.response.data.accessUrl
      this.$forceUpdate()
    },

    /** 上传营业执照图片方式 */
    handleLicenseUrlSuccess (res, file) {
      this.form.licenseUrl = file.response.data.accessUrl
      this.$forceUpdate()
    },

    /** 图片放大处理 */
    showBigImage (url) {
      this.dialogImageUrl = url
      this.dialogImageVisible = true
    },

    /** 图片上传之前的尺寸校验 */
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      return isLt2M
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },

    /** 重置按钮操作 */
    resetQuery () {
      this.resetForm('queryForm')
      this.handleQuery()
    },

    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.tenantId)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.open = true
      this.title = '添加商户信息'
    },

    /** 修改按钮操作 */
    handleUpdate (row) {
      this.reset()
      const tenantId = row.tenantId || this.ids
      getTenant(tenantId).then(response => {
        this.form = response.data
        this.open = true
        this.title = '修改商户信息'
      })
    },

    // 商户状态修改
    handleStatusChange (row) {
      let text = row.status === '0' ? '启用' : '停用'
      this.$modal
        .confirm('确认要"' + text + '""' + row.tenantName + '"商户吗？')
        .then(function () {
          return changeTenantStatus(row.tenantId, row.status)
        })
        .then(() => {
          this.$modal.msgSuccess(text + '成功')
        })
        .catch(function () {
          row.status = row.status === '0' ? '1' : '0'
        })
    },

    /** 提交按钮 */
    submitForm () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.tenantId != null) {
            updateTenant(this.form).then(response => {
              this.$modal.msgSuccess('修改成功')
              this.open = false
              this.getList()
            })
          } else {
            addTenant(this.form).then(response => {
              this.$modal.msgSuccess('新增成功')
              this.open = false
              this.getList()
            })
          }
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete (row) {
      const tenantIds = row.tenantId || this.ids
      this.$modal
        .confirm('是否确认删除商户基本信息编号为"' + tenantIds + '"的数据项？')
        .then(function () {
          return delTenant(tenantIds)
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => {})
    },
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        'system/tenant/export',
        {
          ...this.queryParams
        },
        `tenant_${new Date().getTime()}.xlsx`
      )
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/* 正文内容超出三行隐藏 */
.limitContent {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
