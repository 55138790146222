var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "handleNoticeDialog" },
    [
      _c("c-dialog", {
        attrs: { title: _vm.title, showDialog: _vm.openDialog, width: 800 },
        on: {
          "update:showDialog": function ($event) {
            _vm.openDialog = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openDialog = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "marT20",
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.rules,
                          "label-width": "80px",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "公告标题",
                                      prop: "noticeTitle",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        placeholder: "请输入公告标题",
                                      },
                                      model: {
                                        value: _vm.form.noticeTitle,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "noticeTitle", $$v)
                                        },
                                        expression: "form.noticeTitle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "公告类型",
                                      prop: "noticeType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          size: "mini",
                                          placeholder: "请选择公告类型",
                                        },
                                        model: {
                                          value: _vm.form.noticeType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "noticeType",
                                              $$v
                                            )
                                          },
                                          expression: "form.noticeType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dict.type.sys_notice_type,
                                        function (dict) {
                                          return _c("el-option", {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.label,
                                              value: dict.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "内容" } },
                                  [
                                    _c("editor", {
                                      attrs: { "min-height": 240 },
                                      model: {
                                        value: _vm.form.noticeContent,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "noticeContent",
                                            $$v
                                          )
                                        },
                                        expression: "form.noticeContent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.handleClose } },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.sumbmitForm },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }